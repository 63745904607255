// main
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xz'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'

import UserOnboardModule from '../content/onboard/user-onboard-name'

export default function UserOnboard () {
  const metadata = {
    name: 'New Accounts',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: 'medium',
          data: (
            <>
              <div className=''></div>
            </>
          )
        }}
        media={{ size: 'xtra', data: <></> }}
        content={{
          size: 'mini',
          data: (
            <>
              <WebbDividerMedium />
              <UserOnboardModule />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        footer={{
          size: 'medium',
          data: (
            <>
              <div className=''></div>
            </>
          )
        }}
      />
    </>
  )
}
