// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'
import WebbIcon from '../webx/webb-icon'

import WebbTrackerMedium from '../webx/webb-tracker-md'

export default function TransferDetailsModule (props) {
  const asset = { form: 'ww' }

  const { id } = useParams()
  const [loading, setLoading] = useState(true)

  const [item, setItem] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setItem(props?.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])

  if (loading) return <></>

  return (
    <>
      {/* {// console.log(item)} */}
      <WebbDividerMedium />

      {/* credit */}
      <div className='d-flex mx-3 align-middle'>
        <div className=''>
          {/* <span className='rounded-xx d-none'>
            <img
              src={brand}
              className='rounded-xx p-1 border shadow-sm'
              width={33}
              alt='route brand'
            ></img>
          </span> */}
          <span className='rounded-xx align-middle'>
            <Jazzicon
              diameter={30}
              seed={jsNumberForAddress(
                item?.credit?.item || Date.now().toString()
              )}
            />
          </span>
        </div>
        <div className='ms-2 align-middle'>
          <p
            className='text-normal text-uppercase text-bold m-0'
            style={{ lineHeight: '1rem' }}
          >
            {item?.cred?.name || 'Worldwide Inc'}
          </p>
          <p className='text-small text-color-tone text-uppercase m-0'>
            XXXX-XXXX-XXXX-
            {item &&
              item?.cred?.item?.substring(item?.cred?.item?.length - 4 || 0)}
          </p>
        </div>
        <span className='ms-2 mt-1 mb-1 text-uppercase text-bold'></span>
      </div>

      {/* reason */}
      <WebbDividerMedium />
      <div className='mx-3'>
        <p className='text-small m-0 mb-1'>{'Transfer Reason'}</p>
        <p className='text-normal m-0'>{item?.meta?.memo || 'Data Transfer'}</p>
        {/* <p className='text-normal m-0'>{(item && item.meta.memo) || '******'}</p> */}
      </div>

      {/* user */}
      <WebbDividerMedium />
      <div className='mx-3'>
        <p className='text-small m-0 mb-2'>User</p>

        <div className='d-flex'>
          <div className='d-none'>
            <span className='rounded-xx align-middle'>
              <Jazzicon
                diameter={30}
                seed={jsNumberForAddress(
                  item?.debt?.item || Date.now().toString()
                )}
              />
            </span>
          </div>
          <div className=''>
            <p className='text-bold m-0 mb-2' style={{ lineHeight: '1rem' }}>
              {item?.debt?.mail || 'user@bharat.id'}
            </p>
            <p className='m-0'>{item?.debt?.name || 'User Name'}</p>
          </div>
        </div>

        {/* <p className='text-normal m-0'>{(item && item.debit.mail) || '******'}</p> */}
      </div>

      {/* footer */}
      {props.hideFooter && (
        <div
          className='text-small text-color-tint d-none d-md-block mx-3'
          style={{ position: 'fixed', bottom: '1rem' }}
        >
          <p className='text-small text-color-next m-0 mb-1 mt-2'>
            <span className='text-mini'>
              <i className='bi bi-circle-fill'></i>
            </span>
            <span className='ms-1'>Testnet Transaction for Demo</span>
          </p>
          <WebbTrackerMedium />
          Powered by <span className='text-normal text-bold'>
            BharatID
          </span>{' '}
          <span className='ms-3'></span>
          <span>Terms</span>
          <span className='mx-1'></span>
          <span>Privacy</span>
        </div>
      )}
    </>
  )
}
