import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from '../context/authcontext'

// views - main
import About from '../views.xz/main-xz'

// views - accounts
import AccountsNew from '../views.accounts/accounts-new'
import AccountsCreateRand from '../views.accounts/accounts-create-rand'

// views - transfers
import TransfersData from '../views.transfers/transfers-data'
import TransfersCode from '../views.transfers/transfers-code'

// views - tokens
import TokensMint from '../views.tokens/tokens-mint'
import TokensMintDocs from '../views.tokens/tokens-mint-docs'

// views - assets
import AssetMint from '../views.assets/assets-mint'

// views - auth
import AuthMailCode from '../views.auth/auth-mail-code'
import AuthSessionX from '../views.auth/auth-session-x'
import QrScan from '../views.qr/qr-sacn'
import TmQrScan from '../views.qr/tm-qr-scan'
import AuthNext from '../views.auth/auth-next'
import UserOnboard from '../views.onboard/user-onboard'
import OfferAssetMint from '../views.offers/offers-mint'
import TransfersDataCs from '../views.transfers/transfer-data-cs'
import TmQrScanDemo from '../views.qr/tm-qr-scan-demo'
import TransfersDataDemo from '../views.transfers/transfers-data-demo'

const routes = [
  { route: '/', content: <About />, auth: false },

  // asset - sale
  { route: '/ax/:id', content: <AssetMint />, auth: false }, //

  // asset offer
  { route: '/ox/:od/:id', content: <OfferAssetMint />, auth: false },

  // tokens - mint
  { route: '/mx/:id', content: <TokensMint />, auth: false }, //
  { route: '/md/:id', content: <TokensMintDocs />, auth: false }, //

  // transfers - data
  // { route: '/dx/:id', content: <TransfersData />, auth: false },
  { route: '/dz/:id', content: <TransfersCode />, auth: false },
  { route: '/idx/:id', content: <TransfersData />, auth: false },
  { route: '/ts/:id', content: <TransfersDataCs />, auth: false },

  // auth
  { route: '/auth', content: <AuthMailCode />, auth: false },
  { route: '/auth/next', content: <AuthNext />, auth: false },
  { route: '/auth/x', content: <AuthSessionX />, auth: false },

  // onboard
  { route: '/user/onboard', content: <UserOnboard />, auth: true },

  // accounts
  { route: '/ac/:id', content: <AccountsNew />, auth: false },
  { route: '/ac/416002/:id', content: <AccountsCreateRand />, auth: false },
  { route: '/az/:id', content: <TransfersCode />, auth: false },

  //qr-user
  { route: '/qr/:email', content: <QrScan />, auth: false },
  //qr-tm
  { route: '/tm/:id', content: <TmQrScan />, auth: false },

   //qr-tm-demmo
   { route: '/dm/:id', content: <TmQrScanDemo />, auth: false },
   { route: '/dx/:id', content: <TransfersDataDemo />, auth: false },
]

export default function RouteX () {
  const { user } = useAuth()
  // console.log('user', user)

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route
            key={i}
            path={item.route}
            element={
              !user ? (
                <Navigate
                  to='/auth'
                  state={{ location: window.location.pathname }}
                />
              ) : (
                item.content
              )
            }
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  )
}
