// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'

import { AccountsMinterDetails } from '../../services/srvc-accounts-realm'
import { AccountStatus, AccountInit } from '../../services/srvc-accounts-realm'
import { TextReverse } from '../../services/srvc-encr-node'

import { MetadataCreate } from '../../services/srvc-media-realm'
import { DocumentsLink } from '../../services/srvc-documents-realm'
import { toBase64 } from '../../services/srvc-utilities'

import { TokenDetails } from '../../services/srvc-tokens-realm'

import { TokenMint, TokenClaim, TokenTransfer } from '../../services/srvc-tokens-realm'
import { TransfersAssetSubmit, TransfersAssetDecline } from '../../services/srvc-transfers-assets-realm'

export default function TokenActionDocsModule (props) {
  
  console.log(props.data)

  const asset = { form: 'ww' }
  const navigate = useNavigate()
  const { id } = useParams()
  
  const [loading, setLoading] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('...')

  const [form, setForm] = useState(false)
  
  const [item, setItem] = useState()
  
  const [account, setAccount] = useState({})
  const [secret, setSecret] = useState('')
  const [code, setCode] = useState('')

  const [docs, setDocument] = useState(false)
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [custom, setCustom] = useState('')

  const [size, setSize] = useState('')
  const [filedata, setFileData] = useState('')
  const [fileload, setFileLoader] = useState(false)
  const [filestatus, setFileStatus] = useState(false)
  
  const [memodocs, setMemoDocs] = useState('')

  const [data, setData] = useState({
    name: '', 
    mail: '',
    account: '',
  })

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        
        // token info
        setItem(props?.data)
        var resAccount

        if (props?.data) {
          resAccount = await AccountsMinterDetails ({data: {user: props?.data?.credit?.item|| '******'}})
          // console.log (resAccount)
          
          if (resAccount?.data) setAccount(resAccount?.data?.data?.account)

        }

        if (props?.data) {
          setData({
            ...data, 
            name: props?.data?.credit?.name || '', 
            mail: props?.data?.credit?.mail|| '',
            account: resAccount?.data?.data?.account?.number || '', 
          })
        }        

        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])   


  // form validation
  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

        setForm(false)
        if (
          data && data.name !== "" &&
          data && data.mail !== "" &&
          code && code !== "" && code.length == 6 &&
          account && account.number !== "" &&
          account && account.secret !== "" 
        )
        setForm(true)

      }
      fetchData()
    }
  }, [id, data, account, secret, code])


  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }


  const handleDecline = async() => {

    setLoading(true)
    setSubmit(true)

    // create vars
    // const vars = Array.from(formData, x => { return { ...x, data: 'xxxxxx' }})
    
    //update transfer
    const datx = { item: id }
    const result = await TransfersAssetDecline({data: datx, srvc: '******'})

    // console.log(result)

    if (result?.data?.status == 7) {
      // console.log('done')
      setDone(true)
      // return
      window.location.reload()
    }

    setLoading(false)    

  }  

  const handleDocument = async() => {
    setDocument(!docs)
  }

  const handleFile = async e => {
    
    setMemoDocs('Please Wait...')
    setFileLoader(true)
    setFileStatus(false)
    
    const files = e.target.files[0]
    // console.log(files)

    if (files) {
      console.log(files.name)
      setName(files.name)
      setSize(files.size)
      
      const filedatx = await toBase64(files)
      // console.log(filedatx)
      setFileData(filedatx)

      setMemoDocs('Please Wait... File Upload in Progress')
      const resDocs = await DocumentsLink ({data: {
        "user": item?.credit?.item,
        "creator": item?.credit?.item,
        "checker": item?.credit?.item,
        "document": {
          "name": item?.meta?.name || "******", 
          "number": number || '9999000000000123'
        },
        "cred": [item?.unit?.number || '9000-0000-0000-0123', item?.trxn || ''],
        "custom": [custom],
        "dates": { "start": "", "end": "", "issue": "", "valid": "" },
        "filedata": filedatx
      },
        srvc: '******'
      })
      console.log(resDocs)
      if (resDocs) {
        setMemoDocs('File Upload Success')
        setFileLoader(false);
        setFileStatus(true)
      } else {
        setMemoDocs('File Upload Failed')
        setFileLoader(false)
      }

    }
  };

  const handleTokenSubmit = async() => {

    setLoading(true)
    setSubmit(true)
    // setMemo('Please Wait... Metadata Create')
    setMemo('Please Wait... Token Mint')

    // 1 - metadata create
    const metadata = {
      name: item.unit.name,
      description: item.unit.memo,
      image: item.media.link,
      attributes: [
        { asset: item.asset.number },
        { unit: item.unit.number },
        { reference: '******' },
        { vars: [] }, 
        { role: 'user' }
      ]

    } // item.meta.data
    console.log (item.unit.number)
    var metalink = item?.meta?.link || "******"
    if (metalink == "******") {
      setMemo('Please Wait... Token Metadata')
      const metalinx = await MetadataCreate({
        data: { metadata: metadata, asset: item.asset.number, unit: item.unit.number }, 
        srvc: '******'
      })

      metalink = metalinx?.data?.file?.link || '******'
      console.log (metalink) // metalink.data.link  
    }
    if (metalink == '******') {
      setMemo('Failed');
      return
    }

    // 2 - token mint
    var tokenx = item?.meta?.item || "******"
    setMemo('Please Wait... Token Mint')
    if (tokenx == '******') {
      const mintdata = {
        name: item.unit.name,
        memo: `iam.${item.unit.number}.mint.${item.sale.number}.bharatid`,
        tokenuri: metalink,
        count: item.sale.number,
        ticker: item.sale.ticker,
        chain: '416002'
      }
      const tokenz = await TokenMint({data: mintdata})
      tokenx = tokenz?.data?.asset.toString() || '******'
      console.log(tokenx)
    }

    if (tokenx == '******') {
      setMemo('Failed');

      return
    }


    // 3 - user secret
    setMemo('Please Wait... Account Check')
    const secretx = await TextReverse({text: account.secret, secret: code})
    console.log(secretx)

    // 4 - account balance check
    setMemo('Please Wait... Account Maintenance')
    // const accountx = await AccountStatus({data: {account: account.number, chain: '416002'}})
    // console.log (accountx)

    // 5 - token claim
    setMemo('Please Wait... Token Claim')
    const claimdata = {
      account: "",
      mnemonic: secretx.data,
      asset: tokenx,
      count: item.sale.number.toString(),
      memo: `iam.${item.unit.number}.claim.bharatid`,
      chain: '416002'
    }
    const claimx = await TokenClaim({data: claimdata})
    console.log(claimx)
    if (claimx.code == 400) {
      setMemo('Please Wait... Token Claim Failed')
      return
    }

    // 6 - token transfer
    setMemo('Please Wait... Token Transfer')
    const transferdata = {
      account: account.number,
      asset: tokenx,
      count: item.sale.number.toString(),
      memo: `iam.${item.unit.number}.transfer.${item.sale.number}.bharatid`,
      chain: '416002'
    }
    const transferx = await TokenTransfer({data: transferdata})
    console.log(transferx)  

    if (transferx.code == 400) {
      setMemo('Please Wait... Token Transfer Failed')
      return
    }

    if (transferx.data) {
      setDone(true)
      setMemo('Please Wait... Success')

    } else {
      setMemo('Please Wait... Failed')
    }

    //checkout status set


    setLoading(false)
  }

  const handleSubmit = async () => {

    setLoading(true)
    setSubmit(true)

    //update transfer
    const datx = {
      item: id
    }
    console.log(datx)
    
    const result = await TransfersAssetSubmit({data: datx, srvc: '******'})
    console.log(result)

    if (result?.data?.status == 6) {
      console.log('done')
      setDone(true)
      // return
      window.location.reload()
    }

    setLoading(false)
  }


  return (
    <>
      
      <WebbDividerSmall/>
      <div className='mb-2'></div>
      

      {/* details */}
      <p className='m-0 mx-3 text-normal'>Mint Your Credentials</p>
      <p className='mx-3 text-small'>Please provide the following details:</p>
      <WebbDividerSmall />

      {/* action */}
      <div className='mx-3'>

        {/* user */}
        <div className=''>
        <p className='text-small text-color-tone text-uppercase m-0 mb-1'>{'User Details'}</p>
          <p className='text-normal text-bold text-color-next m-0'>{data?.name || '******'}</p>
          <p className='m-0'>{data?.mail || '******'}</p>
        </div>

        <div className='d-none'>

          <div className='mb-3'>
            <label className="form-label text-small">Name</label>
            <input 
              type="test" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={data?.name}
              onChange={({ target }) => {handleChange("name", target.value); }}
              disabled={loading || data?.name}
              placeholder="BharatID User" />
          </div>
          
          <div className='mb-3'>
            <label className="form-label text-small">Email</label>
            <input 
              type="test" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={data?.mail}
              onChange={({ target }) => {handleChange("mail", target.value); }}
              disabled={loading || data?.mail}
              placeholder="user@bharat.id" />
          </div>

          <div className='mb-3 d-none'>
            <label className="form-label text-small">Credentials Account</label>
            <input 
              type="test" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={data?.account}
              onChange={({ target }) => {handleChange("account", target.value); }}
              disabled={loading || data?.account}
              placeholder="ABCX123..." />
          </div>                
          
        </div>

        <WebbDividerMedium />
        <div className=''>
          <div className=''>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="checkbox" 
              value={docs} 
              onChange={() => handleDocument()}
              id="flexCheckDefault"></input>
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Add Documents
            </label>
          </div>
          </div>
          <div className={docs ? '' : 'd-none'}>
            <div className='mb-3'>
              <label className="form-label text-small">Enter Document Number</label>
              <input 
                type="test" 
                className="form-control"
                style={{fontSize: '0.9rem'}}
                value={number}
                onChange={({ target }) => {setNumber(target.value); }}
                disabled={loading || submit || fileload}
                placeholder="0123..." />
            </div> 

            <div className='mb-3'>
              <label className="form-label text-small">Enter JSON</label>
              <input 
                type="test" 
                className="form-control"
                style={{fontSize: '0.9rem'}}
                value={custom}
                onChange={({ target }) => {setCustom(target.value); }}
                disabled={loading || submit || fileload}
                placeholder="{ ...... }" />
            </div>   

            <div className="mb-1">
              <label className="form-label text-small">Upload Document</label>
              <input 
                className="form-control text-small" 
                type="file" 
                onChange={handleFile}
              />
            </div>
            <div className='mb-3'>
              <p className={`text-mini ${filestatus ? 'text-success' : 'text-color-danger'}`}>
                {memodocs}
              </p>
            </div>

          </div>
        </div>

        <WebbDividerMedium />
        {/* PassCode */}
        <div className='mb-3'>
          <div className='mb-3'>
            <label className="form-label text-small">Please Enter Your Account Passcode</label>
            <input 
              type="password" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={code}
              onChange={({ target }) => {setCode(target.value); }}
              disabled={loading || submit || fileload}
              placeholder="123456..." />
          </div>           
        </div>

        {/* action */}
        <WebbDividerSmall />
        <div className='d-flex justify-content-start'>
            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='submit'
                className='btn btn-primary btn-sm rounded-pill hidark border-none text-small'
                onClick={() => handleSubmit()}
                disabled={loading || fileload}
              >
                Submit
              </button>
            </div>

            <div className='mx-2'></div>

            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='button'
                className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
                onClick={() => handleDecline()}
              >
                Decline
              </button>
            </div>            
          </div>

        <WebbDividerMedium />
      </div>

      {/* action */}

      {/* loader */}
      <div className={`mx-3 ${loading && submit ? '' : 'd-none'}`}>
        <p>{memo}</p>
      </div>

      {/* done */}
      <div className={`mx-3 ${!loading && submit ? '' : 'd-none'}`}>
        <p className={done ? '' : 'd-none'}>
          <i className='bx bxs-check-circle text-color-success'></i>
          <span className='ms-1'>Success</span>
        </p>
        <p className={done ? 'd-none' : ''}>
          <i className='bx bxs-error-circle text-color-danger'></i>
          <span className='ms-1'>Failed</span>          
        </p>
        <p className={done ? 'd-none' : ''} onClick={() => window.location.reload}>
          <span className='p-2 back-color-tint'>Please Try Again</span>
        </p>
      </div>      

    </>
  )
}
