import { useEffect, useState } from 'react'

export default function CalculatorModuleNew (props) {
  const [loader, setLoader] = useState(false)

  const [baseline, setBaseline] = useState({
    base1: '55967',
    base2: '575',
    base3: '6932',
    base4: '25277',
    base5: '9151',
    base6: '100'
  })
  const [basescore, setBaseScore] = useState('000')

  const [score, setScore] = useState('0')

  const [rates, setRates] = useState('000')
  const [rate, setRate] = useState({
    rate1: '',
    rate2: '',
    rate3: '',
    rate4: '',
    rate5: '',
    rate6: ''
  })
  const [data, setData] = useState({})
  const [format, setFormat] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setFormat(props.format)
    }
    fetchData()
  }, [props?.format])

  useEffect(() => {
    setRates(
      parseInt(rate?.rate1 || '0') +
        parseInt(rate?.rate2 || '0') +
        parseInt(rate?.rate3 || '0') +
        parseInt(rate?.rate4 || '0') +
        parseInt(rate?.rate5 || '0') +
        parseInt(rate?.rate6 || '0')
    )
  }, [rate])

  useEffect(() => {
    props?.updateFunc(data)
  }, [data])

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  return (
    <>
      {/* info */}

      <div className='d-flex'>
        <div
          className='d-flex justify-content-start bg-light rounded border'
          style={{ width: '100%' }}
        >
          <div className='' style={{ width: '70%' }}>
            <p className='fw-bold mt-3 ms-3'>Domain</p>
            <div className='border-bottom mb-3'></div>
            {format &&
              format.bs?.bsdata?.map(item => (
                <div key={item.item}>
                  <p
                    className='ms-3'
                    style={{ height: '1.5rem' }}
                    // key={item.item}
                  >
                    <span className='align-middle'>
                      <i className='bx bx-wallet-alt'></i>
                    </span>
                    <span className='ms-2'></span>
                    <span className='align-middle'>{item.name}</span>
                  </p>
                  <div className='border-bottom mb-3'></div>
                </div>
              ))}
            <p className='ms-3 fw-bold' style={{ height: '1.5rem' }}>
              Score
            </p>
            <div className='border-bottom d-none'></div>
          </div>

          {/* Data */}
          <div className='bg-light' style={{ width: '50%' }}>
            <p className='fw-bold mt-3 ms-3'>Data</p>
            <div className='border-bottom mb-3'></div>
            {format &&
              format.bs?.bsdata?.map((item, i) => {
                const name = item.key
                return (
                  <div key={item.item}>
                    <input
                      // key={item.item}
                      type='text'
                      className='form-control height-md m-0 mb-2 mx-3'
                      style={{
                        fontSize: '0.9rem',
                        height: '2rem',
                        width: '60%'
                      }}
                      value={data[name]}
                      onChange={({ target }) => {
                        handleChange(name, target.value)
                      }}
                      disabled={loader}
                      placeholder='0'
                    ></input>
                    <div className='border-bottom mb-3'></div>
                  </div>
                )
              })}
            <p className={`ms-3 text-${rates <= 100 ? 'secondary' : 'danger'}`}>
              {rates === '' ? '000' : rates}
            </p>
            <div className='border-bottom d-none'></div>
          </div>

          <div className='bg-light text-end d-none' style={{ width: '25%' }}>
            <p className='fw-bold mt-3 me-3'>Score</p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>
              {((data.data1 / format?.bs?.value) * rate.rate1 * 9).toFixed(0)}
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>
              {((data.data2 / format?.bs?.value) * rate.rate2 * 9).toFixed(0)}
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>
              {((data.data3 / baseline.base3) * rate.rate3 * 9).toFixed(0)}
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>
              {((data.data4 / baseline.base4) * rate.rate4 * 9).toFixed(0)}
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>
              {((data.data5 / baseline.base5) * rate.rate5 * 9).toFixed(0)}
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3'>
              {((data.data6 / baseline.base6) * rate.rate6 * 9).toFixed(0)}
            </p>
            <div className='border-bottom mb-3'></div>
            <p className='me-3 fw-bold'>{score}</p>
            <div className='border-bottom d-none'></div>
          </div>
        </div>
      </div>
      <div className='mb-3'></div>
    </>
  )
}
