// main
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xz'
import WebbHeader from '../content/webz/webb-header-xx'
import WebbFooterMobile from '../content/webz/webb-footer-mobile'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'

import AssetStatusModule from '../content/assets/asset-status'
import AssetLoaderModule from '../content/assets/asset-loader'
import AssetDetailsModule from '../content/assets/asset-details'
import AssetActionModule from '../content/assets/asset-action'
import AssetActionXXModule from '../content/assets/asset-action-xx'

import { AssetUnitDetails } from '../services/srvc-assets-units-realm'

export default function AssetMint () {
  const metadata = {
    name: 'Mint Asset',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const asset = { user: '******' }
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [status, setStatus] = useState()
  const [done, setDone] = useState(false)

  const [item, setItem] = useState()
  const [user, setUser] = useState('')
  const [reset, setReset] = useState(false)

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const datx = { item: id }
        const result = await AssetUnitDetails({
          data: datx,
          client: '',
          service: ''
        })
        console.log(result)

        if (result.stat) setItem(result.data)
        setStatus({ code: result?.code })
        setLoader(false)
      }
      fetchData()
    }
  }, [id, reset])

  const handleStatus = async item => {}

  const handleDone = async item => {
    setDone(item)
  }

  if (loader) return <AssetLoaderModule />
  // if (!loader && !item) return <>Asset Not Found</>

  if (!loader && status && [404, 406, 409, 410, 412].includes(status.code)) {
    return <AssetStatusModule data={status} />
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{ size: 'mini', visible: false, data: <> </> }}
        media={{ size: 'xtra', data: <></> }}
        content={{
          size: 'xtra',
          data: (
            <>
              <div className={`container-fluid ${done ? 'd-none' : ''}`}>
                <div className='row'>
                  <div className='col-md-1 d-none d-md-block back-color-wite'></div>
                  <div className='col-md-5 back-color-wite'>
                    <AssetDetailsModule data={item} />
                  </div>

                  <div
                    className='col-md-5 back-color-lite d-none d-md-block'
                    style={{ minHeight: '100vh' }}
                  >
                    {/* <AssetActionModule data={item} /> */}
                    <AssetActionXXModule
                      data={item}
                      status={handleStatus}
                      done={handleDone}
                    />
                  </div>

                  <div className='col-md-5 back-color-lite d-md-none'>
                    {/* <AssetActionModule data={item} status={handleStatus}/> */}
                    <AssetActionXXModule data={item} status={handleStatus} />

                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                  </div>

                  <div
                    className='col-md-1 back-color-lite d-none d-md-block'
                    style={{ minHeight: '100vh' }}
                  ></div>
                </div>
              </div>

              <div
                className={`container-fluid ${
                  done ? 'back-color-wite' : 'd-none'
                }`}
                style={{ minHeight: '100vh' }}
              >
                <div className='row '>
                  <div className='col-md-4 d-none d-md-block back-color-wite'></div>
                  <div className='col-md-4 back-color-wite'>
                    <WebbDividerSmall />
                    <div className='text-center mx-3'>
                      <p className='text-small mb-1'>{item?.webx?.number}</p>
                      <img
                        src={item?.media?.link}
                        className='w-100 rounded-xd'
                      ></img>
                    </div>

                    <WebbDividerSmall />
                    <div className='text-center mx-3'>
                      <p className='text-normal text-bold mb-2'>
                        {item?.meta?.name}
                      </p>
                      <p
                        className='text-small text-md'
                        style={{ justifyContent: 'full' }}
                      >
                        {item?.meta?.memo}
                      </p>

                      <div className='mb-2'></div>
                      <div className='border-bottom'></div>
                      <div className='mb-2'></div>
                      <i className='bx bxs-check-circle text-icon-md text-color-success'></i>
                      <p className='text-bold'>Asset Minted</p>
                      <p className=''>
                        You can access the ticket/pass from your account.
                      </p>
                      <WebbDividerMedium />
                      <span
                        className='text-small back-color-lite rounded-xd p-2 px-3 cursor hidark'
                        onClick={() => window.location.reload()}
                      >
                        Mint Again
                      </span>
                    </div>

                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <p className='text-small text-color-tone text-center'>
                      You can close this window.
                    </p>

                    <WebbDividerMedium />
                  </div>
                  <div className='col-md-4 d-none d-md-block back-color-wite'></div>
                </div>
              </div>
            </>
          )
        }}
        footer={{
          size: 'medium',
          data: (
            <>
              <div className='text-center back-color-lite fixed-bottom pt-2 mb-1 d-none'>
                {''}
              </div>
            </>
          )
        }}
      ></ContentFormat>
    </>
  )
}
