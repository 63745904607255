import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormatDetails } from '../../services/srvc-formats-realm'
import {
  TransferDataDecline,
  TransferDataSubmit
} from '../../services/srvc-transfers-data-realm'
import { getModelData } from '../../services/srvc-ts-model'
import WebbDividerSmall from '../webx/webb-divider-sm'
import { apiConfig } from './config'
import TransferActionIdsCSModule from './transfers-ids-cs-action'
import TransferTruescoreCSActionModule from './transfers-ts-cs-action'

export default function TransferCSCombineActionModule (props) {
  const asset = { form: 'www' }
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [item, setItem] = useState('')
  const [formData, setformData] = useState([])
  const [mdDatax, setMdDatax] = useState()
  const [idxDatax, setIdxDatax] = useState([])
  const [retainedForm, setRetainedForm] = useState({
    msgToIdx: '',
    hideAction: false
  })
  const [idxCredDatax, setIdxCredDatax] = useState([])

  const TS_ID = '2130ecd037524189ab788c1671932f7c8'
  const CURR = 81

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        setItem(props.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id])

  useEffect(() => {
    const fetch = async () => {
      //format details
      setLoading(true)
      const payload = {
        data: {
          item: item.format.item
        }
      }
      const res = await FormatDetails(payload)
      if (res.data.data) {
        setformData(res.data.data)
      }
      setLoading(false)
    }
    if (item) {
      fetch()
    }
  }, [item])

  const handleDecline = async () => {
    setLoading(true)
    setSubmit(true)

    // create vars
    const vars = Array.from(item.vars, x => {
      return { ...x, data: 'xxxxxx' }
    })

    //update transfer
    const datx = { item: id, vars }
    const result = await TransferDataDecline({ data: datx, srvc: '******' })
    if (result?.data?.status == 7) {
      // console.log('done')
      setDone(true)
      window.location.reload()
    }

    setLoading(false)
  }

  const updateTsStateData = async datax => {
    setMdDatax(datax)
  }
  const updateIdxStateData = async (datax, credx, rform) => {
    setIdxDatax(datax)
    setIdxCredDatax(credx)
    if (credx) {
      setRetainedForm({
        ...rform,
        memo: 'File uploaded successfully',
        hideAction: true,
        code: true,
        media: credx[0].data[0].media?.link || ''
      })
    }
  }
  const prepareIdxData = async () => {
    console.log('dataaa', idxDatax)
    console.log('da2', idxCredDatax)
    const varx1 = item.vars.map(item => {
      const obj = idxDatax && idxDatax?.find(x => x.taxxon === item.taxxon)
      const asset = obj.list?.filter(x => x.select)
      return { ...item, data: asset && asset.length > 0 ? asset : '****' }
    })
    //for doc upload
    const varx2 = idxCredDatax || []
    const varx = [...varx1, ...varx2].filter(
      item => Array.isArray(item.data) && item.data.length > 0
    )

    return varx
  }
  const prepareTsData = async () => {
    const config = apiConfig.find(
      item => item.value === formData?.bs?.bsmodel?.value
    )
    if (!config && !config?.api) {
      return alert('Invalid Model')
    }
    const payload = {
      model: config.model,
      data: {
        Age: mdDatax[`age`] || 25,
        Annual_Income: (mdDatax[`annual_income`] || 600000) / CURR,
        Monthly_Inhand_Salary: (mdDatax[`mon_inhand_salary`] || 45000) / CURR,
        Num_Bank_Accounts: mdDatax[`no_bank_acc`] || 3,
        Num_Credit_Card: mdDatax[`no_of_credit_cards`] || 2,
        Interest_Rate: 5, //fixed
        Num_of_Loan: mdDatax[`no_of_loans`] || 3,
        Changed_Credit_Limit: 11, //fixed
        Outstanding_Debt: (mdDatax[`os_debt`] || 2400) / CURR,
        Credit_History_Age: 250, //fixed
        Amount_invested_monthly: (mdDatax[`amt_invested_mon`] || 10000) / CURR,
        Monthly_Balance: (mdDatax[`mon_balance`] || 20000) / CURR
      }
    }
    const res = await getModelData(config.api, payload)
    if (!res && !res.turescore) {
      return alert('Error')
    }
    let datax = formData?.bs?.bsdata.map(ts => ({
      ...ts,
      meta: {
        name: ts.name
      },
      taxxon: TS_ID,
      creator: {
        name: item?.debt?.name
      },
      number: mdDatax[ts.key]
    }))
    datax = [
      ...datax,
      {
        meta: {
          name: 'Score'
        },
        taxxon: TS_ID,
        number: parseFloat(res?.turescore).toFixed(2) || 699
      }
    ]
    const varx = formData?.vars
      .map((vars, i) => {
        if (vars.taxxon === TS_ID) {
          return { ...vars, data: datax }
        }
      })
      .filter(item => item)
    return varx
  }

  const handleSubmit = async e => {
    setLoading(true)
    setSubmit(true)
    const idxVars = await prepareIdxData()
    const tsVars = await prepareTsData()
    const all = [...idxVars, ...tsVars]
    const datx = {
      item: id,
      vars: all
    }
    const result = await TransferDataSubmit({ data: datx, srvc: '******' })
    if (result?.data?.status === 6) {
      setDone(true)
      window.location.reload()
    }
    setLoading(false)
  }
  return (
    <>
      <WebbDividerSmall />
      <div className='mb-2'></div>
      <p className='m-0 mx-3 text-normal'>Select Data and Submit</p>
      <p className='mx-3 text-small'>Please select and confirm the transfer:</p>
      {/* data fields */}
      {item && (
        <TransferActionIdsCSModule
          data={item}
          source='link'
          updateFunc={updateIdxStateData}
          retainedForm={retainedForm}
        />
      )}
      {item && formData && (
        <TransferTruescoreCSActionModule
          data={item}
          formatData={formData}
          updateFunc={updateTsStateData}
        />
      )}

      <div className={formData ? 'd-flex justify-content-start' : 'd-none'}>
        <div className={`${submit ? 'd-none' : ''}`}>
          <button
            type='button'
            className='btn btn-primary btn-sm rounded-pill hirich border-none text-small'
            onClick={() => handleSubmit()}
            disabled={!mdDatax}
          >
            Submit
          </button>
        </div>
        <div className='mx-2'></div>
        <div className={`${submit ? 'd-none' : ''}`}>
          <button
            type='button'
            className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
            onClick={() => handleDecline()}
          >
            Decline
          </button>
        </div>
      </div>
      {/* loader */}
      <div className={`mx-3 ${loading && submit ? '' : 'd-none'}`}>
        <p>Please Wait...</p>
      </div>
      {/* done */}
      <div className={`mx-3 ${!loading && submit ? '' : 'd-none'}`}>
        <p className={done ? '' : 'd-none'}>
          <i className='bx bxs-check-circle text-color-success'></i>
          <span className='ms-1'>Success</span>
        </p>
        <p className={done ? 'd-none' : ''}>
          <i className='bx bxs-error-circle text-color-danger'></i>
          <span className='ms-1'>Failed</span>
        </p>
        <p
          className={done ? 'd-none' : ''}
          onClick={() => window.location.reload}
        >
          <span className=''>Please Try Again</span>
        </p>
      </div>
    </>
  )
}
