// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'
import WebbLoaderSmall from '../webx/webb-loader-sm'

import AuthModule from './auth-module'

import { TransfersFundCreate } from '../../services/srvc-transfers-funds-realm'

export default function FormatSubmitModule (props) {
  // // console.log('props', props)
  
  const asset = { form: 'ww' }
  const navigate = useNavigate()
  const { id } = useParams()
  
  const [loading, setLoading] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  
  
  const [auth, setAuth] = useState(false)
  const [user, setUser] = useState({mail: '******'})

  const [item, setItem] = useState('')
  const [data, setData] = useState({ })


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setItem(props?.data)
        
        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        if (auth && user.mail !== '******') {
          handleTransfer()
        }
      }
      fetchData()
    }
  }, [auth, user])


  const handleAuth = async (item) => {
    setAuth(item)
  }

  const handleUser = async (item) => {
    setUser({...user, mail: item})
  }

  const handleTransfer = async () => {
    
    setLoading(true)
    setSubmit(true);

    const datx = {
      user: {name: user.mail, mail: user.mail},
      format: id,
      memo: item?.memo || '******'
    }

    // console.log(datx)
    
    const result = await TransfersFundCreate({data: datx, srvc: '******'})
    // console.log (result)
    
    const basedomain = window.location.host.split(':')[0] === 'localhost' ? `http://${window.location.host}` : `https://${window.location.host}`
    // // console.log(basedomain)

    if (result.data.data) {
      setDone(true)
      window.open(`${basedomain}/dx/${result?.data?.data.item}`, '_self')
      
    }


  }


  return (
    <>
    
      <WebbDividerMedium />
      <WebbDividerSmall />

      <div className={ auth ? 'd-none' : 'mx-3'}>
        {/* info */}
        <p className='m-0 text-normal'>Authenticate</p>
        <p className='text-small'>Please verify email/mobile account to continue:</p>

        {/* user auth */}
        <WebbDividerMedium />
        <AuthModule setAuth={handleAuth} setUser={handleUser}/>
      </div>

      {/* user auth */}
      <div className={ auth ? 'mx-3' : 'd-none'}>
        {/* info */}
        <p className='text-normal'>Create Transaction</p>
        <p className=''>Please wait...</p>        
        <WebbLoaderSmall />

      </div>
      <WebbDividerMedium />
   

    </>
  )
}
