// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'

import {
  TransferDataDecline,
  TransferDataSubmit
} from '../../services/srvc-transfers-data-realm'
import { UserAssetList } from '../../services/srvc-userx-realm'

import aadhaar from '../../data.media/aadhaar.png'
import digilocker from '../../data.media/digi-locker.png'
import driver from '../../data.media/driver_license.png'
import voter from '../../data.media/voter_card.jpeg'
import pan from '../../data.media/fakepan.png'
import { TAXO_CATEGORIES } from '../../utils'
import AssetIDAadhaarCardModule from './assets-check-aadhaar'
import AssetIDDriverLicenseModule from './assets-check-driverlicense'
import AssetIDVoterCardModule from './assets-check-voter'
import AssetIDPanCardModule from './assets-check-pan'
import PersonalInfoForm from './forms/personal-info-form'
import IdentityInfoForm from './forms/identity-info-form'
import CreditScoreForm from './forms/credit-score-form'

const list = [
  'Identity',
  'Finance',
  '',
  'Social',
  'Academics',
  '',
  'Assets',
  'Work',
  'Health'
]

export default function TransferActionIdsDemoModule (props) {
  const asset = { form: 'ww' }

  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [item, setItem] = useState('')
  const [formData, setformData] = useState([])
  const [assetList, setAssetList] = useState([])
  const [creds, setCreds] = useState()
  const [credForm, setCredForm] = useState()
  const [sort, setSort] = useState([])
  const [step, setStep] = useState("1")
  const [info, setInfo] = useState([])
  const [identity, setIdentity] = useState([])
  const [creditScore, setCreditScore] = useState([])

  const [showPanAadhar, setShowPanAadhaar] = useState(false)
  const [showCredit, setShowCredit] = useState(false)


  const excludeTaxxo = [
    '1435bad88b624449aa04734c500fc17d5',
    '221e45dd818c430e958c1c822311c8167'
  ] //age,bank stmt

  const filePath = [
    { text: 'aadhaar', file: aadhaar },
    { text: 'driver', file: driver },
    { text: 'voter', file: voter },
    { text: 'pan', file: pan }
  ]
  const identityForms = [
    {
      id: '991ce8bbc6a145c99953a2cfdc71485e8',
      text: 'aadhaar',
      component: AssetIDAadhaarCardModule
    },
    {
      id: 'a5701222fe2843eb98e7ada55208b10c7',
      text: 'driver',
      component: AssetIDDriverLicenseModule
    },
    {
      id: 'a09816b184724e388e4800bb9f55d84b9',
      text: 'voter',
      component: AssetIDVoterCardModule
    },
    {
      id: 'bb129591f335470a82c26a92740a7c9c0',
      text: 'pan',
      component: AssetIDPanCardModule
    }
  ]
  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        setItem(props.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id])
 
 
  useEffect(() => {
    const fetch = async () => {
      const result = await UserAssetList({ data: { user: item.debt.item } })
      if (result?.data?.stat) {
        const assetList = result.data.data.list
        const transformedItems = item.vars.map(item => {
          const foundList = assetList
            .filter(asset => asset.taxxon === item.taxxon)
            .map(asset => ({ ...asset, select: false }))
          const domain = TAXO_CATEGORIES.find(
            domain => domain.mainCode === parseInt(item.code[0])
          )
          return {
            domain: domain.domain,
            taxxon: item.taxxon,
            list: foundList,
            taxxonDetails: { ...item, select: false }
          }
        })

        const sortx = [
          ...new Set(transformedItems.map(item => item.taxxon.substring(0, 1)))
        ] // [ 'A', 'B']
        setSort(
          Array.from(sortx, x => {
            return { code: x, name: list[x - 1] }
          })
        )
        setAssetList(assetList)
        setformData(transformedItems)
      }
    }
    if (item) {
      fetch()
    }
  }, [item])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setForm(false)
        if (formData.filter(x => x.list.find(s => s?.select)).length > 0)
          setForm(true)
      }
      fetchData()
    }
  }, [formData])

  useEffect(() => {
    const selectedCred = formData.find(
      item =>
        (item.list.length && item.list[0]?.select) || item.taxxonDetails.select
    )
    let data = ''
    let cform = ''
    if (selectedCred?.list.length > 0) {
      data = selectedCred.list[0]
      setCreds(data)
      setCredForm(null)
    } else {
      data = selectedCred?.taxxonDetails
      cform = selectedCred?.taxxonDetails
      setCreds(data)
      setCredForm(cform)
    }
  }, [formData])

  // console.log('formats', formData)


  useEffect(()=>{

    var showPanAndAadhaar =  formData.filter(x=> x.taxxon =="112116b5761240fdb089b6695aed906c4" || x.taxxon=="111fbcc1f9c74d7f87dca9b870dd55914")
    console.log(showPanAndAadhaar)

    if(showPanAndAadhaar.length==0){setShowPanAadhaar(false)}
    if(showPanAndAadhaar.length > 0){setShowPanAadhaar(true)}
    var showcreditmodule = formData.filter(x=> x.taxxon =="2130ecd037524189ab788c1671932f7c8")
    if(showcreditmodule.length==0){setShowCredit(false)}
    if(showcreditmodule.length>0){setShowCredit(true)}
    

  },[formData])

  const handleDecline = async () => {
    setLoading(true)
    setSubmit(true)

    // create vars
    const vars = Array.from(item.vars, x => {
      return { ...x, data: 'xxxxxx' }
    })

    //update transfer
    const datx = { item: id, vars }
    const result = await TransferDataDecline({ data: datx, srvc: '******' })
    if (result?.data?.status == 7) {
      setDone(true)
      window.location.reload()
    }

    setLoading(false)
  }

  const handleSelect = async (taxxon, number) => {
    const selectx = [...formData]
    const selected = selectx.map(x => {
      if (x.taxxon === taxxon) {
        if (x.list.length) {
          x.list[0].select = true
        }
        x.taxxonDetails.select = true
      } else {
        if (x.list.length) {
          x.list[0].select = false
        }
        x.taxxonDetails.select = false
      }
      return { ...x }
    })
    setformData(selected)
  }

  console.log(formData)

  const handleSubmit = async () => {
 
    setLoading(true)
    var varx = [...info, ...identity, ...creditScore]
    //update transfer
    const datx = {
      item: id,
      vars: varx
    }
    console.log(datx)
    const result = await TransferDataSubmit({ data: datx, srvc: '******' })
    console.log(result)
    if (result?.data?.status === 6) {
      setDone(true)
      // window.location.reload()
    }
    setLoading(false)
  }

  useEffect(()=>{
    if(step=="4")
    {
      handleSubmit()
    }

  },[step])


  const setBasicInfo=async(item)=>{
    // console.log(item)
    var arr =[]
    arr.push({name:'Full Name (Basic)',code:'131', item:'cb3bd28f6a2144a196b66af2e452f09d1',taxxon:"131c99d903d146bebecd126feebdd45f5", rank:'3', data: { value:item.name}})
    arr.push({name:'Mobile Number',code:'134', item:'94c1f4aa2c4f4de9ad99ae714d5cbff70',taxxon:"134d9a1801e14abb8fd6e2edcf114fd26", rank:'4', data: { value: item.mobile} })
    arr.push({name:'Email Address',code:'133', item:'9ebe20ef5cac41c5b17bce007a7b04be8',taxxon:"133aac13d97d4b77afca34eb032c8c6f0", rank:'5', data: { value: item.email} })
    setInfo((prev)=> [...prev, ...arr])
    if(!showPanAadhar && !showCredit){
      setStep("4")
      return
    }
    if(showPanAadhar)
    {
      setStep("2")
      return
    }
    if(showCredit)
      {
        setStep("3")
        return
      }

    
  }

  const setIdentityInfo=async(item)=>{
    // console.log(item)
    var arr =[]
    if(item.aadhaar) arr.push({name:'Aadhaar',code:'111', item:'991ce8bbc6a145c99953a2cfdc71485e8',taxxon:"111fbcc1f9c74d7f87dca9b870dd55914", rank:'1', data: {value:item.aadhaar, file:item.aadhaarImage} })
    if(item.pan)    arr.push({name:'Permanent Account Number (PAN)',code:'112', item:'b92e20d634ca4980a096872a56722e815',taxxon:"112116b5761240fdb089b6695aed906c4", rank:'2', data: {value: item.pan, file:item.panImage }})
    setIdentity((prev)=>[...prev, ...arr])
    if(showCredit){
      setStep("3")
      return
    }
    if(!showCredit){
      setStep("4")
    }
   
  }

  const setCreditScoreInfo=async(item)=>{
    console.log(item)
    var arr=[]
    arr.push({name:'TrueScore',code:'213', item:'53166c172634486ca283049c8820e8267',taxxon:"2130ecd037524189ab788c1671932f7c8", rank:'6', data: {value:item.creditscore}})
    arr.push({name:'TrueScore Data ',code:'214', item:'24166c172634486ca283049c8820e8267',taxxon:"2130ecd037524189ab788c1671932f7c9", rank:'7', data: {value:item.input}  })
    setCreditScore((prev)=> [...prev, ...arr])
    setStep("4")
   
  }

  const GoBack = async(item)=>{
    setStep(item)
  }

  if(!loading && step=="4"){
    return(
      <p className='text-primary mt-4 mx-3'>Transfer Submitted Successfully</p>
    )
  }

  console.log(showPanAadhar, showCredit)

  return (
    <>

     

      <WebbDividerSmall />
      <div className='mb-2'></div>
      <p className='m-0 mx-3 text-normal'>Provide Input and Submit</p>
      <p className='mx-3 text-small'>Please Complete steps and confirm the transfer:</p>

      <div className={step=="1" ? 'mx-3':'d-none'}>
        <PersonalInfoForm basicInfo = {setBasicInfo} />
      </div>

      <div className={step=="2" && showPanAadhar? 'mx-3':'d-none'}>
        <IdentityInfoForm back ={GoBack}  form= {formData} identityInfo = {setIdentityInfo} />
      </div>

      <div className={step=="3"? 'mx-3':'d-none'}>
        <CreditScoreForm creditScoreInfo = {setCreditScoreInfo} />
      </div>



    </>
  )
}
