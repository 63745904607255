// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'

import iconRand from '../../data.icons/rand.png'
import iconCelo from '../../data.icons/celo.png'
import iconMatic from '../../data.icons/matic.png'

import { InviteDetails } from '../../services/srvc-invites-realm'


const listNetwork = [
  {name: 'Algorand', icon: iconRand, active: true, chain: '416002'},
  {name: 'Celo', icon: iconCelo, active: false, chain: '80001'},
  {name: 'Polygon', icon: iconMatic, active: false, chain: '44787'},
]


export default function AccountsNewModule () {

  const asset = { form: 'ww' }

  // // console.log (props)
  const {id} = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)

  const [data, setData] = useState()


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

        const result = await InviteDetails({data: {item: id}, srvc: ''})
        console.log (result)

        if (result.data) { setData(result.data.data) }

        setLoading(false)
      }
      fetchData()
    }
  }, [])

  const handleClick = (item) => {
    navigate(`/ax/${item}/${id}`)
  }

  if (loading) return <>Please Wait...</>
  if (!loading && !data ) return <>Not Found</>
  if (!loading && data?.status ) return <>Invite Link Invalid</>

  return (
  <>
    
    <div className=''>
      
      <p className=''>Please Select Network</p>
      
      <div className="row row-cols-2 row-cols-md-3 g-2">
      {listNetwork && listNetwork.map((item, i) => ( item.active ? 
        <div className={``} key={i}>
          <div className = {`back-color-wite p-2 px-3 border rounded-xd mb-2 ${item.active ? 'hirich cursor': 'hilite'}`}>

            <div className='' onClick={() => item.active  ? handleClick(item.chain) : ''}>
              
              <img src={item.icon} className={`rounded-xx w-50 shadow-sm`} alt = {item.name}
                style={{filter: `grayscale(${item.active ? '0': '1'})`}}
              ></img>

              <WebbDividerMedium />
              <WebbDividerMedium />
              
              <div className={item.active ? '' : 'text-color-tone'}>
                <p className='text-uppercase m-0'>{item.name}</p>
                <p className='text-small m-0'>{'Testnet'}</p>
              </div>

            </div>
            <div className='mb-2'></div>

          </div>  
        </div>
      :''))}
      </div>

    </div>

    {/* footer */}

  </>
  )

}