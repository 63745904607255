// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'

import {
  TransferDataDecline,
  TransferDataSubmit
} from '../../services/srvc-transfers-data-realm'
import { UserAssetList } from '../../services/srvc-userx-realm'

import aadhaar from '../../data.media/aadhaar.png'
import digilocker from '../../data.media/digi-locker.png'
import driver from '../../data.media/driver_license.png'
import voter from '../../data.media/voter_card.jpeg'
import pan from '../../data.media/fakepan.png'
import { TAXO_CATEGORIES } from '../../utils'
import AssetIDAadhaarCardModule from './assets-check-aadhaar'
import AssetIDDriverLicenseModule from './assets-check-driverlicense'
import AssetIDVoterCardModule from './assets-check-voter'
import AssetIDPanCardModule from './assets-check-pan'

const list = [
  'Identity',
  'Finance',
  '',
  'Social',
  'Academics',
  '',
  'Assets',
  'Work',
  'Health'
]

export default function TransferActionIdsModule (props) {
  const asset = { form: 'ww' }

  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [item, setItem] = useState('')
  const [formData, setformData] = useState([])
  const [assetList, setAssetList] = useState([])
  const [creds, setCreds] = useState()
  const [credForm, setCredForm] = useState()
  const [sort, setSort] = useState([])

  const excludeTaxxo = [
    '1435bad88b624449aa04734c500fc17d5',
    '221e45dd818c430e958c1c822311c8167'
  ] //age,bank stmt

  const filePath = [
    { text: 'aadhaar', file: aadhaar },
    { text: 'driver', file: driver },
    { text: 'voter', file: voter },
    { text: 'pan', file: pan }
  ]
  const identityForms = [
    {
      id: '991ce8bbc6a145c99953a2cfdc71485e8',
      text: 'aadhaar',
      component: AssetIDAadhaarCardModule
    },
    {
      id: 'a5701222fe2843eb98e7ada55208b10c7',
      text: 'driver',
      component: AssetIDDriverLicenseModule
    },
    {
      id: 'a09816b184724e388e4800bb9f55d84b9',
      text: 'voter',
      component: AssetIDVoterCardModule
    },
    {
      id: 'bb129591f335470a82c26a92740a7c9c0',
      text: 'pan',
      component: AssetIDPanCardModule
    }
  ]
  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        setItem(props.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id])

  useEffect(() => {
    const fetch = async () => {
      const result = await UserAssetList({ data: { user: item.debt.item } })
      if (result?.data?.stat) {
        const assetList = result.data.data.list
        const transformedItems = item.vars.map(item => {
          const foundList = assetList
            .filter(asset => asset.taxxon === item.taxxon)
            .map(asset => ({ ...asset, select: false }))
          const domain = TAXO_CATEGORIES.find(
            domain => domain.mainCode === parseInt(item.code[0])
          )
          return {
            domain: domain.domain,
            taxxon: item.taxxon,
            list: foundList,
            taxxonDetails: { ...item, select: false }
          }
        })

        const sortx = [
          ...new Set(transformedItems.map(item => item.taxxon.substring(0, 1)))
        ] // [ 'A', 'B']
        setSort(
          Array.from(sortx, x => {
            return { code: x, name: list[x - 1] }
          })
        )
        setAssetList(assetList)
        setformData(transformedItems)
      }
    }
    if (item) {
      fetch()
    }
  }, [item])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setForm(false)
        if (formData.filter(x => x.list.find(s => s?.select)).length > 0)
          setForm(true)
      }
      fetchData()
    }
  }, [formData])

  useEffect(() => {
    const selectedCred = formData.find(
      item =>
        (item.list.length && item.list[0]?.select) || item.taxxonDetails.select
    )
    let data = ''
    let cform = ''
    if (selectedCred?.list.length > 0) {
      data = selectedCred.list[0]
      setCreds(data)
      setCredForm(null)
    } else {
      data = selectedCred?.taxxonDetails
      cform = selectedCred?.taxxonDetails
      setCreds(data)
      setCredForm(cform)
    }
  }, [formData])

  // console.log('formats', formData)

  const handleDecline = async () => {
    setLoading(true)
    setSubmit(true)

    // create vars
    const vars = Array.from(item.vars, x => {
      return { ...x, data: 'xxxxxx' }
    })

    //update transfer
    const datx = { item: id, vars }
    const result = await TransferDataDecline({ data: datx, srvc: '******' })
    if (result?.data?.status == 7) {
      setDone(true)
      window.location.reload()
    }

    setLoading(false)
  }

  const handleSelect = async (taxxon, number) => {
    const selectx = [...formData]
    const selected = selectx.map(x => {
      if (x.taxxon === taxxon) {
        if (x.list.length) {
          x.list[0].select = true
        }
        x.taxxonDetails.select = true
      } else {
        if (x.list.length) {
          x.list[0].select = false
        }
        x.taxxonDetails.select = false
      }
      return { ...x }
    })
    setformData(selected)
  }

  const handleSubmit = async () => {
    // e.preventDefault()
    setLoading(true)
    // setSubmit(true)
    const varx = item.vars.map(item => {
      const obj = formData.find(x => x.taxxon === item.taxxon)
      const asset = obj.list?.filter(x => x.select)
      return { ...item, data: asset && asset.length > 0 ? asset : '****' }
    })
    //update transfer
    const datx = {
      item: id,
      vars: varx
    }
    const result = await TransferDataSubmit({ data: datx, srvc: '******' })

    if (result?.data?.status === 6) {
      setDone(true)
      // window.location.reload()
    }
    setLoading(false)
  }
  console.log(formData)
  const CredDetails = item => {
    return (
      <div className='m-2'>
        <hr></hr>
        <p className='text-small text-bold text-uppercase text-color-next mb-2'>
          {item?.meta?.name}
        </p>

        <p className='text-small text-bold text-uppercase text-color-next mb-2'>
          {item?.number}
        </p>
        <p className='text-small text-bold text-uppercase text-color-next mb-2'></p>
      </div>
    )
  }

  const CredCards = item => {
    const name = item?.meta?.name || item?.name
    const media =
      item?.media?.link ||
      filePath.find(({ text }) => name.toLowerCase().includes(text))?.file
    return (
      <div className='m-2' style={{ width: '105px' }}>
        <div
          className='justify-content-start cursor'
          onClick={() => handleSelect(item?.taxxon, item?.number)}
        >
          <div className='' style={{ position: 'relative' }}>
            <img
              src={media}
              className='media rounded-wd border'
              style={{ width: '4.2rem', height: '4.2rem' }}
              alt={'...'}
            ></img>
            <div
              className=''
              style={{
                float: 'left',
                position: 'absolute',
                padding: '6px',
                left: '0px',
                top: '0px',
                zindex: '99'
              }}
            >
              <i
                className={`text-lead ${
                  item?.select
                    ? 'bx bxs-check-circle text-color-success'
                    : 'bx bx-circle text-color-lite'
                }`}
              ></i>
            </div>
          </div>
        </div>
        <div className='justify-content-start'>
          <p className='text-bold m-0'>{name}</p>
        </div>
      </div>
    )
  }

  const TempCredCreate = singleCred => {
    const Component = identityForms.find(
      ({ id }) => id === singleCred.item
    ).component
    return (
      <>
        <div className='p-2 mb-2'>
          <p className='text-small'>Option 1: Share document via DigiLocker</p>
          <div className='d-flex'>
            <img
              src={digilocker}
              className='media rounded-wd border'
              style={{ width: '160px', height: '100px' }}
              alt={'...'}
            ></img>
            <p className='text-bold m-2'>
              Upload from DigiLocker (Comming Soon...)
            </p>
          </div>
        </div>
        <div className='p-2'>
          <p className='text-small'>Option 2: Upload Document</p>
        </div>
        <Component data={singleCred} user={item.debt} trxn={item} />
      </>
    )
  }

  return (
    <>
      <WebbDividerSmall />
      <div className='mb-2'></div>

      {/* credit */}
      <p className='m-0 mx-3 text-normal'>Select Data and Submit</p>
      <p className='mx-3 text-small'>Please select and confirm the transfer:</p>

      <p className='mx-3 text-small'>Submit 1 of {formData.length} Document</p>
      {/* data fields */}
      <div className='mx-3'>
        {sort &&
          sort.map((item, z) => (
            <>
              <p
                className='text-small text-bold text-uppercase text-color-next mb-2'
                key={z}
              >
                {item.name}
              </p>
              <div className='d-flex'>
                {formData &&
                  formData.map((item, i) =>
                    item.taxxon.substring(0, 1) === sort[z].code ? (
                      item.list?.length ? (
                        <CredCards {...item.list[0]} key={i} />
                      ) : (
                        <CredCards {...item.taxxonDetails} key={i} />
                      )
                    ) : (
                      ''
                    )
                  )}
                <div className='border-bottom mb-3'></div>
              </div>
            </>
          ))}
        {creds && <CredDetails {...creds} />}
        {credForm && <TempCredCreate {...credForm} />}
        <WebbDividerSmall />

        {!credForm && (
          <div
            className={
              formData.length > 0 ? 'd-flex justify-content-start' : 'd-none'
            }
          >
            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='button'
                className='btn btn-primary btn-sm rounded-pill hirich border-none text-small'
                onClick={() => handleSubmit()}
                disabled={!form}
              >
                Submit
              </button>
            </div>

            <div className='mx-2'></div>
            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='button'
                className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
                onClick={() => handleDecline()}
              >
                Decline
              </button>
            </div>
          </div>
        )}

        <WebbDividerMedium />
      </div>

      {/* action */}

      {/* loader */}
      <div className={`mx-3 ${loading && submit ? '' : 'd-none'}`}>
        <p>Please Wait...</p>
      </div>

      {/* done */}
      <div className={`mx-3 ${!loading && submit ? '' : 'd-none'}`}>
        <p className={done ? '' : 'd-none'}>
          <i className='bx bxs-check-circle text-color-success'></i>
          <span className='ms-1'>Success</span>
        </p>
        <p className={done ? 'd-none' : ''}>
          <i className='bx bxs-error-circle text-color-danger'></i>
          <span className='ms-1'>Failed</span>
        </p>
        <p
          className={done ? 'd-none' : ''}
          onClick={() => window.location.reload}
        >
          <span className=''>Please Try Again</span>
        </p>
      </div>
    </>
  )
}
