import React, { useEffect, useState } from 'react';
import FormNeeded from '../../webx/form-needed';
import AWS from 'aws-sdk';
import { Alert } from 'bootstrap';


const IdentityInfoForm = (props) => {
  const [formData, setFormData] = useState({
    aadhaar: '',
    pan: '',
    aadhaarImage: null,
    panImage: null
  });
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAdhr, setShowAdhr] = useState(false)
  const [showPan, setShowPan] = useState(false)

  AWS.config.update({
    accessKeyId:  process.env.REACT_APP_AWS_ACCESS_KEY,  //'AKIAZCHEONTB4AERCUNK',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, //'uRT5Lihj9ecNyWvM6nRpgiX6mb90rZPY/FpP4xDZ',
    region: 'ap-east-1', // e.g., 'us-east-1'
  });

  const s3 = new AWS.S3();


  useEffect(()=>{

    var showPan =  props.form.filter(x=> x.taxxon =="112116b5761240fdb089b6695aed906c4")
    var showAdhr =  props.form.filter(x=> x.taxxon == "111fbcc1f9c74d7f87dca9b870dd55914")
    if(showPan.length > 0 ) setShowPan(true)
    if(showAdhr.length > 0 ) setShowAdhr(true)
    

  },[props])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleImageChange =async (e) => {
    const { name, files } = e.target;

    var fileLink = handleUpload(name,files[0])
    setSelectedFile(files[0])
    // setFormData({
    //   ...formData,
    //   [name]: files[0]
    // });
   
  };

  const handleSubmit = (e) => {
    // console.log(formData);
    if(showAdhr && showPan)
    {
      if(formData.aadhaar && formData.aadhaarImage && formData.pan && formData.panImage)
        {
            props.identityInfo(formData)
        }
        else{
            alert("provide all Input ")
        }
    }
    if(showAdhr && !showPan)
    {
      if(formData.aadhaar && formData.aadhaarImage )
        {
            props.identityInfo({aadhaar:formData.aadhaar, aadhaarImage: formData.aadhaarImage })
        }
        else{
            alert("provide all Input ")
        }
    }

    if(showPan && !showAdhr)
      {
        if(formData.pan && formData.panImage )
          {
              props.identityInfo({pan:formData.pan, panImage: formData.panImage })
          }
          else{
              alert("provide all Input ")
          }
      }
    
    
  };



  const handleUpload = async (name,file) => {
    if (!file) {
      alert('Please choose a file first!');
      return;
    }

    setUploadStatus(true)
    const params = {
      Bucket: '690262c15fcf46dd92024ab04b9eae879', // Replace with your bucket name
      Key: `690262c15fcf46dd92024ab04b9eae879/${file.name}`, // File name you want to save as in S3
      Body: file,
    //   ACL: 'public-read', // Make the file publicly accessible (optional)
      ContentType: file.type,
    };

    // Upload the file to S3
    // return await s3.upload(params)
    var result 
    await s3.upload(params, (err, data) => {
      if (err) {
        // console.error('Error uploading file:', err);
        alert(`${selectedFile?.name} could not uploaded, please choose other file or try again`)
        setUploadStatus(false);
      } else {
        // console.log('File uploaded successfully:', data);
        setUploadStatus(`File uploaded successfully. File URL: ${data.Location}`);
        setFormData({
            ...formData,
            [name]: data.Location
          });
          setUploadStatus(false)
      }
    });
   
  };

  return (
    <div>

      <div className={showAdhr? '' : 'd-none'}>
        <div>
          <label className='mt-3'>Aadhaar <FormNeeded/></label>
          <input
            className='form-control'
            type="text"
            name="aadhaar"
            value={formData.aadhaar}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label className='mt-2'>Upload Aadhaar Image <FormNeeded/></label>
          <input
          className='form-control'
            type="file"
            name="aadhaarImage"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
      </div>

    <div className={showPan? '' : 'd-none'}>
      <div>
        <label className='mt-4'>PAN <FormNeeded/></label>
        <input
        className='form-control'
          type="text"
          name="pan"
          value={formData.pan}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label className='mt-2'>Upload PAN Image <FormNeeded/> </label>
        <input
        className='form-control'
          type="file"
          name="panImage"
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>

      </div>

      <p className={uploadStatus ? 'mt-2 text-small text-center text-warning':'d-none'}>{selectedFile?.name} is uploading...</p>
      <div className=' mt-4'>
        {/* <div className='me-auto'>
        <span  onClick={()=>props.back("1")} className='d-none btn rounded-xx btn-outline-primary text-small float-end mt-3  ' style={{width:'75px'}} type="submit">Back</span>

        </div> */}
        <div>
            <button  onClick={()=>handleSubmit()} className='btn rounded-xx btn-primary text-small float-end mt-3 w-100 ' type="submit">Next</button>
        </div>
      </div>

      {/* <div onClick={()=>handleUpload()} className='mt-5'>Upload</div> */}
      
    </div>
  );
};

export default IdentityInfoForm;
