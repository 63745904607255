import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import CalculatorModuleNew from './calculator-new'

export default function TransferTruescoreCSActionModule (props) {
  const asset = { form: 'www' }
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState('')
  const [formData, setformData] = useState(props?.formatData)

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        setItem(props.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id])
  const updateStateData = async datax => {
    props?.updateFunc(datax)
  }
  return (
    <>
      <hr></hr>
      <WebbDividerSmall />
      <p className='text-small'>Credit Score</p>

      {/* data fields */}
      <div className='mx-3'>
        <div className='ids' style={{ height: '400px', overflow: 'scroll' }}>
          {formData && (
            <CalculatorModuleNew
              format={props?.formatData}
              updateFunc={updateStateData}
            />
          )}
        </div>
        <WebbDividerMedium />
      </div>
    </>
  )
}
