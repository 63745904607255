// init
import axios from 'axios'
const protectedApi =
  'https://ap-south-1.aws.data.mongodb-api.com/app/bharat-tokens-dztkx/endpoint'
const base = 'https://srvc.bharat.id/xx'
export const UserAssetList = async item => {
  const basx = `${protectedApi}/users/assets`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_NMBR
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    // console.log(error)
    return { data: { code: error.response.status, ...error.response.data } }
  }
}

export const UserDetails = async item => {
  const basx = `${base}/users.details`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_SCRT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    // console.log(error)
    return { data: { code: error.response.status, ...error.response.data } }
  }
}
export const UserAccountCreate = async item => {
  const basx = `${base}/users.create`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_SCRT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    // console.log(error)
    return { data: { code: error.response.status, ...error.response.data } }
  }
}
export const UserAccountList = async item => {
  const basx = base + '/users/accounts'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_SCRT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    console.log(result)
    return [200, 201].includes(result.status) ? result.data : { data: false }
  } catch (error) {
    console.log(error)
    return { data: false }
  }
}
