// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbIcon from '../webx/webb-icon'

const listStatus = [
  {code: 404, status: 99, memo: 'Asset Not Found'},
  {code: 201, status: 0, memo: 'Asset Created'},
  {code: 200, status: 1, memo: 'Asset Active'},
  {code: 412, status: 2, memo: 'Asset Scheduled'},
  {code: 423, status: 3, memo: 'Asset Locked / On Hold'},
  {code: 410, status: 4, memo: 'Asset Cancelled'},
  {code: 406, status: 5, memo: 'Asset Timeout'},
  {code: 409, status: 6, memo: 'Asset Closed (Submitted)'},
  {code: 405, status: 7, memo: 'Asset Closed (Declined)'},
  {code: 422, status: 8, memo: 'Asset Revoked'},
  {code: 415, status: 9, memo: 'Asset Active (Partial Data)'}
]
export default function AssetStatusModule (props) {

  const asset = { form: 'ww' }

  // // console.log (props)
  const {id} = useParams()
  const [loading, setLoading] = useState(true)

  const [status, setStatus] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setStatus(props?.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])

  if (loading) return <></>

  return (
  <>
    
    <div className='back-color-lite' style={{height:'100vh'}}>
      <WebbDividerMedium />
      <WebbDividerMedium />
      <div className='text-center'>
        <WebbIcon data={{ color: 'text-color-tone', size: 'text-icon-md' }} />
        <WebbDividerSmall />

        <p className={``}>{status&& listStatus.find(x => x.code == status?.code).memo || '******'}</p>

        <WebbDividerMedium />
        <div className='text-color-tone text-small d-none'>
          <p className='m-0'>Asset ID</p>
          <p className='m-0'>{id}</p>
        </div>
      </div>

      {/* footer */}
      <div className='text-color-tone text-center fixed-bottom'>
        <p className='m-0 text-small'>BharatID</p>
        <p className='m-0 text-mini'>{'Website • Terms • Privacy Policy'}</p>
        <WebbDividerMedium />
      </div>


    </div>
  
  </>
  )

}