// main
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";


import FormatLoaderModule from "../content/formats/formats-loader";
import FormatStatusModule from "../content/formats/formats-status";
import FormatDetailsModule from "../content/formats/formats-details";
import FormatSubmitModule from "../content/formats/formats-submit";

import { FormatDetails } from '../services/srvc-formats-realm'


export default function TransfersCode() {
  
  const metadata = {
    name: 'Transfer',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  const asset = { user: '******' }
  const { id } = useParams()
  
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState()

  const [item, setItem] = useState()
  const [user, setUser] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        const input = { data: {item: id}, srvc: '1234' }

        const result = await FormatDetails(input)
        // console.log (result)

        if (result) {
          setStatus({code: result?.data?.code})
          setItem(result?.data?.data)
        }

        // // console.log(resStatus)
        // setStatus({code: resStatus?.data?.data.status})

        // if (resStatus?.data?.data.status == 200) {

        //   const resTransfer = await FormatDetails(input)
        //   // console.log (resTransfer)
        //   setItem(resTransfer?.data?.data)

        // }

        setLoading(false)
      }
      fetchData()
    }
  }, [id])

  if (loading)  return <FormatLoaderModule />

  if (!loading && status && [404, 406, 409, 410, 412].includes(status.code)) {
    return <FormatStatusModule data={status} />
  }


  return(

  <>
    <Helmet>
      <title>
        {metadata.name}{' • '}
        {process.env.REACT_APP_WEBB_SITE_NAME}{' • '}
        {process.env.REACT_APP_WEBB_SITE_LINE}
      </title>
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'mini', visible: false, data: <> </> }}
      media = {{ size: 'xtra', data: <></> }}

      content = {{ size: 'xtra', data: 
      <>
        <div className="container-fluid">
          <div className='row'>
            <div className='col-md-1 d-none d-md-block back-color-wite'></div>
            <div className='col-md-5 back-color-wite'>
              <FormatDetailsModule data={item} />
              <WebbDividerMedium />
            </div>

            <div className='col-md-5 back-color-lite  d-none d-md-block'
              style={{ minHeight: '100vh' }}
            >
              <FormatSubmitModule data={item}/>
            </div>
          
            <div className='col-md-5 back-color-lite d-md-none'> 
              <FormatSubmitModule data={item}/>
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </div>

            <div className='col-md-1 back-color-lite d-none d-md-block'
              style={{ minHeight: '100vh' }}
            ></div>
          </div>          
        </div>
        

      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <> 
        <div className="text-center back-color-lite fixed-bottom pt-2 mb-1 d-none">
          www.bharat.id
        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}