// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbIcon from '../webx/webb-icon'

import iconRand from '../../data.icons/rand.png'
import iconCelo from '../../data.icons/celo.png'
import iconMatic from '../../data.icons/matic.png'

import { CreateRandWallet } from '../../services/srvc-wallet-rand'

import { InviteDetails } from '../../services/srvc-invites-realm'
import { AccountsMinterCreate } from '../../services/srvc-accounts-realm'


import { TextSecure, TextReverse } from '../../services/srvc-encr-node'


export default function AccountsCreateRandModule () {

  const asset = { form: 'ww' }

  // // console.log (props)
  const {id} = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState()
  const [accountstatus, setAccountStatus] = useState(false)
  const [account, setAccount] = useState({
    address: '******',
    mnemonic: '******',
  })

  const [secret, setSecret] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

        const result = await InviteDetails({data: {item: id}, srvc: ''})
        console.log (result)

        if (result.data) { setData(result.data.data) }

        setLoading(false)
      }
      fetchData()
    }
  }, [])


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

        account.address !== '******' ? setAccountStatus(true) : setAccountStatus (false)
        account.mnemonic !== '******' ? setAccountStatus(true) : setAccountStatus (false)
      
      }
      fetchData()
    }
  }, [account])

  const handleAccount = async () => {
    
    const accountx = await CreateRandWallet()
    console.log(accountx)

    setAccount({...account, address: accountx.data.address, mnemonic: accountx.data.mnemonic})

  }


  const handleSubmit = async() => {
    
    setLoading(true)
    setSubmit(true)

    const mnemonix = await TextSecure( {text: account.mnemonic, secret: secret})
    console.log(mnemonix)

    const datx = {
      account: {
        address: account.address,
        secret: mnemonix.data
      },
      auth: secret, // we can hash secret before saving
      bank: { name: 'Algorand', chain: '416002' },
      user: { name: data.user.name, mail: data.user.mail, item: data.user.item },
      item: id
    }

    setAccount({...account, mnemonic: '******'})

    console.log(datx)

    const result = await AccountsMinterCreate({data: datx, srvc: '******'})
    console.log(result)

    if (result.data) {
      setDone(true)
    }

    setLoading(false)
    // reverse
    // const txt = await ReverseText({text: mnemonix.data, secret: '123'})
    // console.log(txt)

    // if (txt.data === account.mnemonic) console.log('pass') 
    // else console.log('fail')

  }

  function handleSave() {

    const content = '' + 
      '\n--------------------------------------------------\n' +
      '\nBharatID * Privacy First Data Ownership and Monetization' + 
      '\nvisit: https://bharat.id' + 
      '\n\n--------------------------------------------------\n' +
      '\n\nDigital Credentials Account Number\n> ' + account.address + 
      // '\n\nView Account on Mainnet\n> ' + `https://algoexplorer.io/address/${data.address}` +
      // '\n\nView Account on Testnet\n> ' + `https://testnet.algoexplorer.io/address/${data.address}` +
      '\n\n--------------------------------------------------\n' +
      '\n\nRecovery Phrase (Mnemonic/Secret)\n' + '*** DO NOT SHARE THIS WITH ANYONE ***\n> ' + account.mnemonic +
      '\n\n--------------------------------------------------\n' +
      '\nIf you lose your recovery phrase or private key, you will not be able to access your wallet or its funds. It is always a good idea to back it up either by writing down in private diary or printing it out.' + 
      '\n\n--------------------------------------------------\n'

    const blob = new Blob([content], {type: 'text/plain'});

    if(window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, `bharatid-account-${Date.now().toString()}`);
    }
    else{
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = `bharatid-account-${Date.now().toString()}`;
        document.body.appendChild(elem);
        elem.click();  
        document.body.removeChild(elem);
    }
  }

  // if (loading) return <></>

  return (
  <>
    
    <div className=''>
      
      <p className='text-bold'>Create Digital Credentials Account</p>
      <WebbDividerSmall/>

      <p className='text-bold m-0'>Account Number</p>
      <p className='text-normal text-color-next m-0  text-break'>{account.address}</p>

      <WebbDividerMedium />
      <p className='text-bold m-0'>Mnemonic/Secret</p>   
      <p className='text-normal text-color-next m-0 text-break'>{account.mnemonic}</p>

      <div className={accountstatus || submit ? 'd-none' : ''}>
        <WebbDividerMedium />
        <WebbDividerMedium />
        <button 
          className='btn btn-sm btn-primary rounded-xx text-small px-4' 
          style={{width:'auto'}}
          onClick={() => handleAccount()}
        >
          Create Account
        </button>
      </div>
      <WebbDividerMedium />
    </div>


    <div className={accountstatus ? '' : 'd-none'}>

      <p className='m-0 mb-2'>Enter Encryption Key (6 digit)</p>
      <div className="mb-3">  
        <input type="password" className="form-control height-md  "
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={secret}
          onChange={({ target }) => {setSecret(target.value); }}
          disabled={loading || submit}
          placeholder="123456">
        </input>
      </div>

      <div className={!loading && submit ? 'd-none' : ''}>
        <button 
          className='btn btn-sm btn-primary rounded-xx text-small px-4' 
          disabled={loading || submit}
          style={{width:'auto'}}
          onClick={() => handleSubmit()}
        >
          Encrypt and Link Account
        </button>
      </div>

    </div>


    {/* status */}
    <div className={!loading && submit ? '' : 'd-none'}>
      <div  className={done ? 'text-bold' : 'd-none'}>
        <span><i className='bx bxs-check-circle text-color-success'></i></span>
        <span className='ms-1'>Account Link Success</span>
      </div>
      <div  className={done ? 'd-none' : ''}>
        <span><i className='bx bxs-error-circle text-color-danger'></i></span>
        <span className='ms-1'>Account Link Failed</span>
      </div>
    </div>

    {/* memo */}
    <div className={account.address === '******' ? 'd-none' : ''}>
      <WebbDividerMedium />
      <WebbDividerMedium />
      <p className='text-color-main m-0 cursor' onClick={() => handleSave()}>
        <i className='bx bxs-info-circle'></i>
        <span className='ms-1'>Click here to Download Account Details</span>
      </p>
    </div>


    {/* footer */}

  </>
  )

}