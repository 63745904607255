//date-time-location
import { useEffect, useState } from "react"


export default function WebbTrackerMedium() {

  const [date, setDate] = useState(new Date());
  const [location, setLocation] = useState();

  useEffect(() => {
    const timer = setInterval(() => { // Creates an interval which will update the current data every minute
    // This will trigger a rerender every component that uses the useDate hook.
    setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      fetch('https://ipapi.co/json/')
      .then(function(response) {
        response.json().then(jsonData => {
          // console.log(jsonData);
          setLocation(jsonData)
        });
      })
      .catch(function(error) {
        console.log(error)
      });

    }
    fetchData()
  }, []);

  return (
  <>
    <div className="">
      <p className="m-0">
        {location?.country || '******'}
        {' • '}   
        {location?.city || '******'}     
        {' • '}   
        {date.toLocaleString()}
      </p>
    </div>
  </>
  )
}