import { useParams } from 'react-router-dom'
import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-xw'
import ContentFormat from '../content/webz/content-format-xz'
import { Helmet } from 'react-helmet'

export default function Error (props) {
  const asset = { form: 'ww' }
  const { id } = useParams()
  const metadata = {
    name: 'Authorize Data Transfer',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }
  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: 'medium',
          visible: true,
          data: (
            <>
              <WebbHeader data={{ name: metadata.name, home: '/', link: '' }} />
            </>
          )
        }}
        media={{ size: 'xtra', data: <></> }}
        content={{
          size: 'medium',
          data: (
            <>
              <div className='container-fluid'>
                <div className='back-color-lite' style={{ height: '100vh' }}>
                  <WebbDividerMedium />
                  <WebbDividerMedium />
                  <div className='text-center'>
                    {/* <WebbIcon
                      data={{ color: 'text-color-tone', size: 'text-icon-md' }}
                    /> */}
                    <WebbDividerSmall />
                    <p className=''>Transaction UnAuthorized: 401</p>
                    <WebbDividerMedium />
                    <div className='text-color-tone text-small'>
                      <p className='m-0'>Transaction ID</p>
                      <p className='m-0'>{id}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }}
        footer={{
          size: 'medium',
          data: (
            <>
              <div className='text-color-tone text-center fixed-bottom'>
                <p className='m-0 text-small'>BharatID</p>
                <p className='m-0 text-mini'>
                  {'Website • Terms • Privacy Policy'}
                </p>
                <WebbDividerMedium />
              </div>
            </>
          )
        }}
      />
    </>
  )
}
