// init
import axios from 'axios'
const base = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/bharat-accounts-nhscd/endpoint'


export const InviteDetails = async item => {

  const basx = base + '/invites/details'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_NMBR
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    // console.log(error)
    return { data: { code: error.response.status, ...error.response.data } }
  }

}

export const InviteDataSet = async item => {

  const basx = base + '/invites/status/set'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_NMBR
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    // console.log(error)
    return { data: { code: error.response.status, ...error.response.data } }
  }

}
