// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'
import WebbIcon from '../webx/webb-icon'

import WebbTrackerMedium from '../webx/webb-tracker-md'

export default function QrScanDetails (props) {
  const asset = { form: 'ww' }

  console.log(props)
  const { id } = useParams()
  const [loading, setLoading] = useState(true)

  const [item, setItem] = useState([])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setItem(props?.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])

  function downloadToFile (content, filename, contentType) {
    const a = document.createElement('a')
    const file = new Blob([content], { type: contentType })

    a.href = URL.createObjectURL(file)
    a.download = filename
    a.click()

    URL.revokeObjectURL(a.href)
  }

  // function previewFile (event) {
  //   let reader = new FileReader()
  //   let file = event.target.files[0]

  //   reader.readAsDataURL(file)
  //   reader.onloadend = () => (previewEl.src = reader.result)
  // }

  const makeVCardVersion = () => `VERSION:3.0`
  const makeVCardInfo = info => `N:${'Contact'}`
  const makeVCardName = name => `FN:${name}`
  const makeVCardTitle = title => `TITLE:${'Mr/Miss'}`
  // const makeVCardPhoto = img => `PHOTO;TYPE=JPEG;ENCODING=b:[${img}]`
  const makeVCardTel = phone => `TEL;TYPE=WORK,VOICE:${phone}`
  // const makeVCardAdr = address => `ADR;TYPE=WORK,PREF:;;${address}`
  const makeVCardEmail = email => `EMAIL:${email}`
  const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`

  function makeVCard () {
    let vcard = `BEGIN:VCARD
  ${makeVCardVersion()}
  ${makeVCardInfo()}
  ${makeVCardName(item?.name)}
  ${makeVCardTitle()}
  ${makeVCardTel(item?.mobile)}
  ${makeVCardEmail(item?.mail)}
  ${makeVCardTimeStamp()}
  END:VCARD`
    downloadToFile(vcard, 'vcard.vcf', 'text/vcard')
  }
  const saveCard = e => {
    makeVCard()
  }

  const Details = props => (
    <>
      <div className='mx-3'>
        <p className='text-small m-0 mb-1'>{props?.label}</p>
        <p className='text-normal m-0'>{props?.value || '*****'}</p>
      </div>
      <WebbDividerMedium />
    </>
  )
  if (loading) return <></>
  console.log(item)
  return (
    <>
      <WebbDividerMedium />

      {/* credit */}
      <div className='d-flex mx-3 align-middle'>
        <div className=''>
          {/* <span className='rounded-xx d-none'>
            <img
              src={brand}
              className='rounded-xx p-1 border shadow-sm'
              width={33}
              alt='route brand'
            ></img>
          </span> */}
          <span className='rounded-xx align-middle'>
            <Jazzicon
              diameter={30}
              seed={jsNumberForAddress(item?.name || Date.now().toString())}
            />
          </span>
        </div>
        <div className='ms-2 align-middle'>
          <p
            className='text-normal text-uppercase text-bold m-0'
            style={{ lineHeight: '1rem' }}
          >
            {item?.name || 'Worldwide Inc'}
          </p>
          <p className='text-small text-color-tone text-uppercase m-0'>
            XXXX-XXXX-XXXX-
            {item &&
              item?.credit?.item?.substring(
                item?.credit?.item?.length - 4 || 0
              )}
          </p>
        </div>
        <span className='ms-2 mt-1 mb-1 text-uppercase text-bold'></span>
      </div>

      <WebbDividerMedium />
      {[
        { label: 'Name', value: item?.name },
        { label: 'Email', value: item?.mail },
        { label: 'Mobile', value: item?.mobile }
      ].map((item, i) => (
        <Details {...item} key={i} />
      ))}

      <div className={'d-flex justify-content-centre'}>
        <div className={``}>
          <button
            type='button'
            className='btn btn-primary btn-sm rounded-pill hirich border-none text-small'
            onClick={saveCard}
          >
            Save
          </button>
        </div>

        <div className='mx-2'></div>

        <div className={``}>
          <button
            type='button'
            className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
            // onClick={() => handleDecline()}
          >
            Cancel
          </button>
        </div>
      </div>
      {/* footer */}
      <div
        className='text-small text-color-tint d-none d-md-block mx-3'
        style={{ position: 'fixed', bottom: '1rem' }}
      >
        <p className='text-small text-color-next m-0 mb-1 mt-2'>
          <span className='text-mini'>
            <i className='bi bi-circle-fill'></i>
          </span>
          <span className='ms-1'>Testnet Transaction for Demo</span>
        </p>
        <WebbTrackerMedium />
        Powered by <span className='text-normal text-bold'>BharatID</span>{' '}
        <span className='ms-3'></span>
        <span>Terms</span>
        <span className='mx-1'></span>
        <span>Privacy</span>
      </div>
    </>
  )
}
