//date-time
import { useEffect, useState } from "react"


export default function WebbDateTimeMedium() {

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => { // Creates an interval which will update the current data every minute
    // This will trigger a rerender every component that uses the useDate hook.
    setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  return (
  <>
    <div className="">
      <p className="m-0">Date: {date.toLocaleString()}</p>
    </div>
  </>
  )
}