// init
import axios from 'axios'
import services from '../data.services/data-services-snbx.json'

const base = services.data.find(x => x.code == 'transfers.funds').link

// -----------------

export const TransfersFundCreate = async item => {

  const basx = base + '/transfers/funds/create'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundAccountCredit = async item => {

  const basx = base + '/transfers/funds/account/credit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}



export const TransfersFundAccountDebit = async item => {

  const basx = base + '/transfers/funds/account/debit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}



export const TransfersFundStatus = async item => {

  const basx = base + '/transfers/funds/status'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundStatusSet = async item => {

  const basx = base + '/transfers/funds/status/set'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundDetails = async item => {

  const basx = base + '/transfers/details'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}



export const TransfersFundSubmit = async item => {
  
  const basx = base + '/transfers/funds/submit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item?.client || process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

