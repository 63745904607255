// main
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";


import TokenLoaderModule from "../content/tokens/token-loader";
import TokenStatusModule from "../content/tokens/token-status";
import TokenDetailsModule from "../content/tokens/token-details";
import TokenActionDocsModule from "../content/tokens/token-action-docs";

import { TransfersAssetStatus, TransfersAssetDetails } from "../services/srvc-transfers-assets-realm";


export default function TokensMintDocs() {
  
  const metadata = {
    name: 'Asset Mint and Transfer',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  const asset = { user: '******' }
  const { id } = useParams()
  
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState()

  const [item, setItem] = useState()
  const [user, setUser] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        
        const datx = { data: {item: id}, srvc: '1234' }

        const resStatus = await TransfersAssetStatus(datx)
        console.log(resStatus)
        setStatus({code: resStatus?.data?.status})

        if ([200, 201].includes(resStatus?.data?.status)) {

          const resTransfer = await TransfersAssetDetails(datx)
          // console.log (resTransfer)
          setItem(resTransfer?.data)

        }


        setLoading(false)
      }
      fetchData()
    }
  }, [id])

  if (loading)  return <TokenLoaderModule />

  if (!loading && status && [404, 406, 409, 410, 412].includes(status.code)) {
    return <TokenStatusModule data={status} />
  }


  return(

  <>
    <Helmet>
      <title>
        {metadata.name}{' • '}
        {process.env.REACT_APP_WEBB_SITE_NAME}{' • '}
        {process.env.REACT_APP_WEBB_SITE_LINE}
      </title>
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'mini', visible: false, data: <> </> }}
      media = {{ size: 'xtra', data: <></> }}

      content = {{ size: 'xtra', data: 
      <>
        <div className="container-fluid">
          <div className='row'>
            <div className='col-md-1 d-none d-md-block back-color-wite'></div>
            <div className='col-md-5 back-color-wite'>
              <TokenDetailsModule data={item} />
              <WebbDividerMedium />
            </div>

            <div className='col-md-5 back-color-lite d-none d-md-block'
              style={{ minHeight: '100vh' }}
            >
              <TokenActionDocsModule
                data={item}
              />
            </div>
          
            <div className='col-md-5 back-color-lite d-md-none'> 
              <TokenActionDocsModule
                data={item}
              />
              
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </div>

            <div className='col-md-1 back-color-lite d-none d-md-block'
              style={{ minHeight: '100vh' }}
            ></div>
          </div>          
        </div>
        

      </>
      }}
    
      footer = {{ size: 'medium', data: 
      <> 
        <div className="text-center back-color-lite fixed-bottom pt-2 mb-1 d-none">
          www.bharat.id
        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}