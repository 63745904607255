// site icon

import iconmedia from '../../data.media/bharatid-icon.png'

const listColor = [
  { theme: 'dark', color: 'text-color-wite' },
  { theme: 'lite', color: 'text-color-2023' }
]

export default function WebbIconMedia (props) {
  const data = props.data
  
  return (
    <div className='' >
      <img src={iconmedia} className={``} style={{height:'1.15rem'}}></img>
    </div>
  )
}
