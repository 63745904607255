export const apiConfig = [
  {
    name: 'Auto Loan Model',
    api: 'https://7hgodc4o4rb7oaw226oqwpjlae0rolfq.lambda-url.ap-south-1.on.aws/truescore/auto_loan',
    value: 'auto_loan_model',
    model: 'auto_loan'
  },
  {
    name: 'Mortgage Loan Model',
    api: 'https://7hgodc4o4rb7oaw226oqwpjlae0rolfq.lambda-url.ap-south-1.on.aws/truescore/mortgage_loan',
    value: 'mortgage_loan_model',
    model: 'mortgage_loan'
  },
  {
    name: 'Payday Loan Model',
    api: 'https://7hgodc4o4rb7oaw226oqwpjlae0rolfq.lambda-url.ap-south-1.on.aws/truescore/payday_loan',
    value: 'payday_loan_model',
    model: 'payday_loan'
  },
  {
    name: 'Personal Loan Model',
    api: 'https://7hgodc4o4rb7oaw226oqwpjlae0rolfq.lambda-url.ap-south-1.on.aws/truescore/personal_loan',
    value: 'personal_loan_model',
    model: 'personal_loan'
  },
  {
    name: 'Student Loan Model',
    api: 'https://7hgodc4o4rb7oaw226oqwpjlae0rolfq.lambda-url.ap-south-1.on.aws/truescore/student_loan',
    value: 'student_loan_model',
    model: 'student_loan'
  }
]
