// next

export default function NextUser () {
  return (
    <div
      className={`p-2 px-3 back-color-next text-color-wite rounded-xx hidark cursor`}
    >
      <div className='d-flex'>
        <div className=''>
          <i className='bx bx-user-circle m-0 text-icon-sm'></i>
        </div>
        <div className='py-1 ms-2 text-normal'>Create User Account</div>
        <div className='ms-auto'>
          <i className='bx bx-chevron-right m-0 text-icon-sm'></i>
        </div>
      </div>
    </div>
  )
}
