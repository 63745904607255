// main
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";


import TokenLoaderModule from "../content/tokens/token-loader";
import TokenStatusModule from "../content/tokens/token-status";
import TokenDetailsModule from "../content/tokens/token-details";
import TokenActionModule from "../content/tokens/token-action";

import { TransfersAssetStatus, TransfersAssetDetails } from "../services/srvc-transfers-assets-realm";


export default function TokensMint() {
  
  const metadata = {
    name: 'Asset Mint and Transfer',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }



  const asset = { user: '******' }
  const { id } = useParams()
  
  const [loader, setLoader] = useState(true)
  const [status, setStatus] = useState()
  const [done, setDone] = useState(false)

  const [item, setItem] = useState()
  const [user, setUser] = useState('')
  const [reset, setReset] = useState(false)


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        
        const datx = {item: id}
        const resStatus = await TransfersAssetStatus({
          data: datx, client: '', service: ''
        })
        //console.log(resStatus)
        setStatus({code: resStatus?.data?.status})

        if ([200, 201].includes(resStatus?.data?.status)) {

          const resTransfer = await TransfersAssetDetails({
            data: datx, client: '', service: ''
          })
          // console.log (resTransfer)
          if (resTransfer.stat) setItem(resTransfer?.data)

        }

        setLoader(false)
      }
      fetchData()
    }
  }, [id])

  const handleStatus = async(item) => {
    
  }

  const handleDone = async(item) => {
    setDone(item)
  }

  if (loader)  return <TokenLoaderModule />

  if (!loader && status && [404, 406, 409, 410, 412].includes(status.code)) {
    return <TokenStatusModule data={status} />
  }

  //console.log(item)

  return(

  <>
    <Helmet>
      <title>
        {metadata.name}{' • '}
        {process.env.REACT_APP_WEBB_SITE_NAME}{' • '}
        {process.env.REACT_APP_WEBB_SITE_LINE}
      </title>
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'mini', visible: false, data: <> </> }}
      media = {{ size: 'xtra', data: <></> }}

      content = {{ size: 'xtra', data: 
      <>
        <div className={`container-fluid ${done ? 'd-none' : ''}`}>
          <div className='row'>
            <div className='col-md-1 d-none d-md-block back-color-wite'></div>
            <div className='col-md-5 back-color-wite'>
              <TokenDetailsModule data={item} />
              <WebbDividerMedium />
            </div>

            <div className='col-md-5 back-color-lite d-none d-md-block'
              style={{ minHeight: '100vh' }}
            >
              <TokenActionModule data={item} />
            </div>
          
            <div className='col-md-5 back-color-lite d-md-none'> 
              <TokenActionModule data={item} status={handleStatus} done={handleDone}   />
              
              
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </div>

            <div className='col-md-1 back-color-lite d-none d-md-block'
              style={{ minHeight: '100vh' }}
            ></div>
          </div>          
        </div>

        <div className={`container-fluid ${done ? 'back-color-wite' : 'd-none'}`} style={{minHeight: '100vh'}}>

        <div className='row '>
          <div className='col-md-4 d-none d-md-block back-color-wite'></div>
          <div className='col-md-4 back-color-wite'>
            
            <WebbDividerSmall />
            <div className="text-center mx-3">
              <p className="text-small mb-1">{item?.webx?.number}</p>
              <img src={item?.media?.link} className="w-100 rounded-xd"></img>
            </div>
            
            
            <WebbDividerSmall />
            <div className="text-center mx-3">
              <p className="text-normal text-bold mb-2">{item?.meta?.name}</p>
              <p className="text-small text-md" style={{justifyContent: 'full'}}>{item?.meta?.memo}</p>
            
              <div className="mb-2"></div>
              <div className="border-bottom"></div>
              <div className="mb-2"></div>
              <i className="bx bxs-check-circle text-icon-md text-color-success"></i>
              <p className="text-bold">Asset Minted</p>
              <p className="">You can access the ticket/pass from your account.</p>
              <WebbDividerMedium />
              <span className="text-small back-color-lite rounded-xd p-2 px-3 cursor hidark" 
                onClick={() => window.location.reload()}
              >Mint Again</span>
            </div>

            <WebbDividerMedium />
            <WebbDividerMedium />
            <p className="text-small text-color-tone text-center">You can close this window.</p>

            <WebbDividerMedium />
          </div>
          <div className='col-md-4 d-none d-md-block back-color-wite'></div>
        </div>
      </div>


      </>
      }}
    



      footer = {{ size: 'medium', data: 
      <> 
        <div className="text-center back-color-lite fixed-bottom pt-2 mb-1 d-none">
          www.bharat.id
        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}