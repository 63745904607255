// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'
import WebbIcon from '../webx/webb-icon'

const brand =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNMcstvDKUbEOF3ATaINPOjbXkA-tGkoYd7djlqW7VO53gYdt_e_gQ4-G_-deVTrY1L2Y&usqp=CAU'

export default function FormatDetailsModule (props) {
  
  const asset = { form: 'ww' }

  const {id} = useParams()
  const [loading, setLoading] = useState(true)

  const [item, setItem] = useState()
  const [owner, setOwner] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setItem(props?.data)
        setOwner(props?.data.user.find(x =>x.role === 'owner'))
        
        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])

  if (loading) return <></>
  
  return (
    <>

      <WebbDividerMedium />
      <WebbDividerSmall />

      {/* credit */}
      <div className='d-flex mx-3 align-middle'>
        <div className=''>
          {/* <span className='rounded-xx d-none'>
            <img
              src={brand}
              className='rounded-xx p-1 border shadow-sm'
              width={33}
              alt='route brand'
            ></img>
          </span> */}
          <span className='rounded-xx align-middle'>
            <Jazzicon diameter={30} seed={jsNumberForAddress(owner && owner.item || Date.now().toString())} /> 
          </span>
        </div>
        <div className='ms-2 align-middle'>
          <p className='text-normal text-uppercase text-bold m-0' style={{lineHeight: '1rem'}}>
            {(owner && owner.name) || 'Worldwide Inc'}
          </p>
          <p className='text-small text-color-tone text-uppercase m-0'>
            XXXX-XXXX-XXXX-{owner && owner.item.substring(owner.item.length-4)}
          </p>
        </div>
        <span className='ms-2 mt-1 mb-1 text-uppercase text-bold'>
          
        </span>
      </div>



      {/* reason */}
      <WebbDividerMedium />
      <div className='mx-3'>
        <p className='text-normal text-bold m-0 mb-1'>Form: {item?.name || 'Format Name'}</p>
        <p className='m-0 mb-3'>Details: {item?.memo || 'Format Memo'}</p>
        <p className='m-0'>Domain: <span className='text-uppercase'>{item?.domain || 'Format Domain'}</span></p>
        {/* <p className='text-normal m-0'>{(item && item.meta.memo) || '******'}</p> */}
      </div>

      <WebbDividerSmall/>
      <div className='mx-3 border-bottom d-none'></div>
      {/* user */}
      <WebbDividerSmall/>
      <div className='mx-3'>
        <p className='m-0 mb-3'>{'Form Details'}</p>
        
        <div className='rounded-md border w-75'>
        {item && item.vars.map((x, i) => (
          <div className='d-flex p-2 px-3 border-bottom' key={i}>
            <div className='me-auto'>{x.name}</div>
            <div className='text-uppercase text-color-tone text-mini mt-1 d-none'>{x.mime}</div>
          </div>

        ))}
        </div>


      </div>





      {/* footer */}
      <div
        className='text-small text-color-tint d-none d-md-block mx-3'
        style={{ position: 'fixed', bottom: '2.4rem' }}
      >
        <p className='text-small text-color-danger m-0 mb-2'>
          Testnet Transaction for Demo Only ***
        </p>
        Powered by <span className='text-normal text-bold'>BharatID</span>{' '}
        <span className='ms-3'></span>
        <span>Terms</span>
        <span className='mx-1'></span>
        <span>Privacy</span>
      </div>
    </>
  )
}
