// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Lottie from 'react-lottie';
import medialottie from '../../data.media/alerts-success.json'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'

// auth
import { AuthCodeCreate, AuthCodeCheck } from '../../services/srvc-auth-realm'

// user account
import { UserAccountCreate } from '../../services/srvc-users-realm';
import { UserAccountDetails, UserAccountDetailsBharatID } from '../../services/srvc-users-realm'
import { AccountsBaseBalance, AccountsBaseBalanceCredit } from '../../services/srvc-accountx-realm'

// user minter
import { AccountsMinterDetails, AccountsMinterNew } from '../../services/srvc-accounts-realm'
import { AccountsMinterStatus, AccountsMinterInit } from '../../services/srvc-accounts-realm'
import { TextReverse } from '../../services/srvc-encr-node'

// creator account
import { TeamAccountDetails } from '../../services/srvc-users-creators-realm'

// user creds
import { CredentialsListUserMembers } from '../../services/srvc-credentials-realm'

// transfers - funds 
import { TransfersFundAccountCredit, TransfersFundStatusSet } from '../../services/srvc-transfers-funds-realm'
import { TransfersFundCreate, TransfersFundSubmit } from '../../services/srvc-transfers-funds-realm'

// transfers - assets
import { TransfersAssetCreate } from '../../services/srvc-transfers-assets-realm'
import { TransferAssetSubmit, TransferAssetDecline } from '../../services/srvc-transfers-assets-realm'

import { AssetUnitDataMintSet, AssetUnitDetails } from '../../services/srvc-assets-units-realm'
import { AssetUnitDataBookSet, AssetUnitDataSaleSet } from '../../services/srvc-assets-units-realm'


// tokens
import { MetadataCreate } from '../../services/srvc-media-realm'
import { TokenDetails } from '../../services/srvc-tokens-realm'

import { TokensMintArnd } from '../../services/srvc-tokens-rand-realm'
import { TokensClaimArnd, TokensTransferArnd } from '../../services/srvc-tokens-rand-realm'



import axios from 'axios';
import { IndividualOffersDetails, IndividualOffersStatusSet } from '../../services/srvc-offers-realm';


const member0 = {
  meta: {name: 'General', memo: 'General Audience (All)'}, 
  rate: {number: '', ticker: 'inr'}, 
  number: '3356199999990456',
  taxxon: '69c214c90838489b9ecb35383bebe94e6',
  user: '69c214c90838489b9ecb35383bebe94e6',
  active: false,
  item: '69c214c90838489b9ecb35383bebe94e6',
}

export default function OfferActionModule (props) {

  const asset = { role: 'www' }

  const navigate = useNavigate()
  const { id } = useParams()
  const { od } = useParams()
  
  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('')

  const [form, setForm] = useState(false)
  
  const [creator, setCreator] = useState()

  const [rates, setRates] = useState([])
  const [mode, setMode] = useState('mail')

  const [authcodestatus, SetAuthCodeStatus] = useState(false)
  const [authcodecheck, SetAuthCodeCheck] = useState(false)
  const [authcode, SetAuthCode] = useState('')
  const [trxn, setTrxn] = useState('')
  
  const [accountStatus, setAccountStatus] = useState(false)
  const [account, setAccount] = useState({})
  const [secret, setSecret] = useState('')

  const [minterstatus, setMinterStatus] = useState(false)
  const [minter, setMinter] = useState({number: "", secret: ""})
  
  const [code, setCode] = useState('')
  
  const [user, setUser] = useState()
  const [usercheck, setUserCheck] = useState(false)
  const [userstatus, setUserStatus] = useState(false)
  const [usercreds, setUserCreds] = useState([])

  const [checkoutstatus, setCheckoutStatus] = useState(false)
  const [checkoutdone, setCheckoutDone] = useState(false)

  const [modalCancelTransaction, setModalCancelTransaction] = useState(false)
  const [orderId, setOrderId] = useState('')

  const [order, setOrder] = useState()

  const [item, setItem] = useState()
  const [data, setData] = useState({
    name: '', 
    mail: '',
    user: '',
    account: '',
    bharatid: '',
    code: '', 
    trxn: '',
    mobile:''
  })

  const [amount, setAmount] = useState()
  const [modalCheckoutStatus, setModalCheckoutStatus] = useState(false);

  const [commerce, setCommerce] = useState(false)
  const [modalCommerceStatus, setModalCommerceStatus] = useState(false)

  const [mintstart, setMintStart] = useState(false)
  const [mintdone, setMintDone] = useState(false)
  const [modalMinterStatus, setModalMinterStatus] = useState(false)

  const [reset, setReset] = useState(false)

  const [assetTrxnId, setAssetTrxnId] = useState('')

  const [offer, setOffer] = useState()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: medialottie,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

        var res = await IndividualOffersDetails({data:{item:od}, client:'', service:''})
        console.log(res)
        if(res.stat == true){
          setOffer(res.data)

          const datx = {item: id}
          const result = await AssetUnitDetails({
          data: datx,
          client: '', 
          service: ''
        })
        //console.log(result)

        if (result.stat) {
          setItem(result.data)
          setRates(Array.from(res?.data?.rate, x => {return {
            ...x, 
            active: x.item == '69c214c90838489b9ecb35383bebe94e6',
            status: false
          }}) || [])

          // creator details
          var creatorx = await TeamAccountDetails({
            data: {user: result.data.creator.item}, client: '', service: ''
          })
          //console.log(creatorx)
          if (creatorx.stat) setCreator(creatorx.data)

        }
        }

        
        
        
        
        
        setLoader(false)
      }
      fetchData()
    } else {}
  }, [])

  // console.log(rates)

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        
        // setMemo('Please Wait...')
        //console.log(rates)
        var ratex = Array.from(rates, x => { 
          return {...x, active:true}
        })
        
        var datx = {user: user && user?.item}, credx
        if (user) credx = await CredentialsListUserMembers({data: datx, srvc: '******'})
        //console.log(credx)

        for (let c=0; c < ratex.length; c++) {
          var index = credx?.data?.list?.findIndex(z => z.unit.item == rates[c].item)
          if (index > -1) ratex[c] = {...ratex[c], active: true} 
        }
    
        setRates(rates => [...ratex.filter(x => x.active)])

      }
      fetchData()
    } else {}
  }, [user])


  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const handleCodeCreate = async() => {
    const datx = {
      user: offer?.user?.mail,
      memo: "BharatId Account Access"
    }
    const result = await AuthCodeCreate({data: datx})
    
    if (result.data) {
      setTrxn(result.data.trxn)
      setMemo('OTP sent to Your Email. Please check (SPAM also)')
      SetAuthCodeStatus(true)
    }
    else {

      setMemo('Failed')
    }

  }  

  const handleCodeCheck = async() => {
    
    var status = false

    const datx = {
      user: offer?.user?.mail,
      trxn: trxn,
      code: authcode
    }
    const result = await AuthCodeCheck({data: datx})
    // console.log (result)

    if (result.data==true) {
      setMemo('Success: OTP Verification')
      SetAuthCodeCheck(true)
      status = true
    }
    else {
      setMemo('Failed: OTP Verification')
      SetAuthCodeCheck(false)
    }
    return status
    
  }  


  const handleUserOfferSelect = async(item) => {

    var ratex = Array.from(rates, x => { return {...x, status: false}})
    var x = ratex.findIndex(z => z.item == item)
    ratex[x] = {...ratex[x], status: true}
    setRates(rates => [...ratex])
    console.log(ratex)
    var checkout = ratex.findIndex(z => z.status)
    console.log(checkout)
    if (checkout>-1) setCheckoutStatus(true)

  }

  const handleUserAccount = async () => {
    
    const codecheck = await handleCodeCheck()
    // console.log (codecheck)
    var datx, result 
    if (codecheck && mode=='bharatid') {
      datx = {user: offer?.user?.mail}
      result = await UserAccountDetailsBharatID({data: datx, srvc: '******'})
    } 
    if (codecheck && mode=='mail') {
      datx = {user: offer?.user?.mail}
      result = await UserAccountDetails({data: datx, srvc: '******'})
    }
    
    //console.log(result)
    if(codecheck)
    {
      if (result.stat) {
        setMemo('Please Wait... User Profile')
        setUserStatus(true)
        //console.log(result)
        setUser(result.data)
  
        // setUserCreds(credx.data.list)
        // handleUserOffers(credx.data.list)
        setUserCheck(true)
      }
      else {
        setUserStatus(false)
        setMemo('') // new user account
        setUserCheck(true)
      }
    }
    else{
      return
    }
    setLoader(false)
    // setMemo('')
  }

  const handleCheckout = async() => {
    // modal set
    //console.log(rates)
    const amount = rates[0]?.rate?.number
    setMemo('')
    if(amount == 0)
    {
      modalMinterShow()
    }
    else{
      modalCheckoutShow()
    }
   
    // handleCommerce()


  }  

  const paymentVerificationSave = async(value) => {
    const res = await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-sugga/endpoint/payment/verify", {data:value})
    if(res.data.stat){
      setCommerce(true)
      handleFundTransferUserToCreator()
    }
  }

  const handleCommerce = async()=>{
    
    // setModalCommerceStatus(true)
    setLoader(true)
    var statusCommerce = false, result, res, datx

    // 1: Create Account Credit Fund Transfer - Status 0
    // 2: Create Payment Gateway Transaction - use trxn id from 1 as order ID (PG)
    // 3: If 2 is success, then set Account Credit = 6 (on PG success)
    // 4: Set New Balance (on trigger)
    // 5: Create Fund Transfer (user to creator) = Status 0 (base account)
    // 6: Submit Fund Transfer (user) = Status == 1,  (base account)
    // 7: Set New Balance for User and Creator (automatic trigger)  (base account)


    // 1: fund transfer account credit
    datx = {
      "credit": {
        "name": user?.name || "******", 
        "mail": user?.mail || "******",
        "item": user?.item || "******",
      },
      "debit": {
        "name": user?.name || "******", 
        "mail": user?.mail || "******",
        "item": user?.item || "******",
      },
      "meta": {"name": "account.credit", "memo": "account.credit"},
      "count": {
        "number": rates.find(x=>x.status)?.rate?.nmbr || '0', 
        "ticker": 'INR'
      },
      "rate": {"number": "1", "ticker": ""},
      "asset": {},
      "unit": {}
    }
    res = await TransfersFundAccountCredit({ data: datx, client :'', service: '' })
    // console.log(res)

    // 2 Payment Gateway Call
    var res = await handleCollection(res.data.item)
    
  }

  const handleFundTransferUserToCreator = async()=>{
    var datx = {
      "credit": {
        "name": creator?.name || "******", 
        "mail": creator?.mail || "******",
        "item": creator?.item || "******",
      },
      "debit": {
        "name": user?.name || "******", 
        "mail": user?.mail || "******",
        "item": user?.item || "******",
      },
      "meta": {"name": `${item?.meta?.name || 'sale'}`, "memo": `${item?.meta?.name || 'ticket'} sale` },
      "count": {"number": "1", "ticker": ""},
      "rate": {
        "number": rates.find(x=>x.status)?.rate?.nmbr || '0', 
        "ticker": 'INR'
      },
      "asset": item.asset || {},
      "unit": item.unit || {}
    }
    var res = await TransfersFundCreate({ 
      data: datx, 
      client: creator.creds.client || '', 
      service: creator.creds.service || '' 
    })
    // console.log(res)

    

    // 6: fund transfer submit
    datx = {
      "user": user?.item || "******",
      "item": res?.data?.item || "******",
    }
    res = await TransfersFundSubmit({ data: datx, client :'', service: '' })
    // console.log(res)

    if(res.stat) {
      setCommerce(true)
      AssetTransferCreate()
     var res = await IndividualOffersStatusSet({data:{item:od, active:false}, client:'', service:''})
    //  console.log(res)
      setModalCommerceStatus(false)
      setReset(true)
      modalCommerceClose()
        
    }


  }

  const handleCollection = async(trxn)=>{
    // console.log(rates.find(x=>x.status)?.rate?.nmbr)
    const {data}= await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-sugga/endpoint/order/create", {
      data: {amount:rates.find(x=>x.status)?.rate?.nmbr}
    })
    const dataSave= await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-sugga/endpoint/order/save", {
      data: {...data._rejectionHandler0, stat:0 , trxn:trxn}
    })

    const orderTx = data._rejectionHandler0
    setOrder(orderTx)
    setOrderId(orderTx.id)
    const options = {
    key: "rzp_test_Vpjr5HSfP3SBgV", // process.env.RAZORPAY_APP_KEY
    amount: orderTx.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: data?.creator?.name,
    description: account.credit,
    image: "https://example.com/your_logo",
    order_id: orderTx.id, 
    handler: (res) => {
      // console.log(res)
      paymentVerificationSave(res)
      return res
    },
    prefill: {
        name: user.name,
        email: user.mail,
        contact: "9000090000"
    },
    notes: {
        address: "Razorpay Corporate Office"
    },
    theme: {
        color: "#121212"
    }
    };

    const razor = new window.Razorpay(options);
    razor.open()

  }

  const ContinueOrderPurchase = async() =>{

    const options = {
      key: "rzp_test_Vpjr5HSfP3SBgV", // process.env.RAZORPAY_APP_KEY
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: data.creator?.name,
      description: account.credit,
      image: "https://example.com/your_logo",
      order_id: order.id, 
      handler: (res) => {
        console.log(res)
        paymentVerificationSave(res)
        return res
      },
      prefill: {
          name: user.name,
          email: user.mail,
          contact: "9000090000"
      },
      notes: {
          address: "Razorpay Corporate Office"
      },
      theme: {
          color: "#121212"
      }
      };
      const razor = new window.Razorpay(options);
      razor.open()
    

 }


 const AssetTransferCreate = async() =>{

  // setMintStart(true)
  // setMemo('Please Wait... ')


  var datx = {user: user?.item}
  const minterx = await AccountsMinterDetails({ data: datx, client: '', service: '' })
  //console.log (minterx)
  // setMinterStatus(true)

  //asset transfer create
  const offerx = rates.find(x => x.status)
  var datx = {
    "credit": {
      "name": user?.name || "******", 
      "mail": user?.mail || "******",
      "item": user?.item || "******",
    },
    "debit": {
      "name": creator?.name || "******", 
      "mail": creator?.mail || "******",
      "item": creator?.item || "******",
    },
    "meta": {"name": `${item?.meta?.name || 'sale'}`, "memo": `${item?.meta?.name || 'ticket'} sale` },
    "count": {"number": "1", "ticker": "IAM"},
    "rate": {
      "number": offerx?.rate?.number || '0', 
      "ticker": offerx?.rate?.ticker || '******'
    },
    "asset": item.asset.item || '',
    "unit": item.item || '',
    "webx": item?.webx || '',
    "mode": 'sale',
    "crxx":  null
  }
  // console.log(datx)
  // setMemo('Please Wait... 2')

  var result = await TransfersAssetCreate({ 
    data: datx, 
    client: creator.creds.client || '', 
    service: creator.creds.service || '' 
  })
  //console.log(result)

  if (!result.stat) {
    setMemo('Failed: Asset Transfer Not Created')
    return
  } 
  else{
    setAssetTrxnId(result.data.item)
    setModalMinterStatus(true)
    // navigate(``)
    // setMinterStatus(false)
    return
  }

 }
  


  const handleDecline = async() => {}  
  const handleTokenSubmit = async() => {}
  const handleSubmit = async () => {}


  const modalCheckoutShow = () => { setModalCheckoutStatus(true) };
  const modalCheckoutClose = () => {
    modalCommerceShow()
    setModalCommerceStatus(false)
    setCommerce(false)
    setAmount('')
    setMemo('')
    setModalCheckoutStatus(false);
  };

  const modalCommerceShow = ()=>{
    setModalCommerceStatus(true)
  }
  const modalCommerceClose = ()=>{
    setModalCommerceStatus(false)
  }

  const modalTransactionOpen = () =>{
    setModalCancelTransaction(true)
  }
  const modalTransactionClose = async () =>{
    const res = await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-sugga/endpoint/order/cancel", {data:{id:orderId}})
    if(res.data.stat)
    {
      modalCheckoutClose()
      setModalCancelTransaction(false)
      window.location.reload(true)
    }

  }

  const modalMinterShow = ()=>{
    setModalMinterStatus(true)
  }
  const modalMinterClose = ()=>{
    setModalMinterStatus(false)
  }

  const CreateNewAccount = async()=> {
    setMemo('Please Wait....')
    var result
    var datx = {
      name: data.name,
      mail: data?.user,
      mobile: data?.mobile
    }
    // 1. create user account,
    // 2. create new minter account,
    var res = await UserAccountCreate({data:datx, srvc:''})
   // console.log(res)
    if(res.stat)
    {
      datx.item= res?.data?.item || ''
      var datax= {user: datx}
      var res = await AccountsMinterNew({data:datax, srvc: ''})
      //console.log(res)
      if(res.stat){

        localStorage.setItem("code", res?.data?.account?.code || null )
        setMemo('Account Created Successfully, ')
        var codecheck = true
        if (codecheck && mode=='bharatid') {
          datx = {user: data.user}
          result = await UserAccountDetailsBharatID({data: datx, srvc: '******'})
        } 
        if (codecheck && mode=='mail') {
          datx = {user: data.user}
          result = await UserAccountDetails({data: datx, srvc: '******'})
        }

        if(codecheck)
          {
            if (result.stat) {
              // setMemo('Please Wait... User Profile')
              setUserStatus(true)
              // console.log(result)
              setUser(result.data)
        
              // setUserCreds(credx.data.list)
              // handleUserOffers(credx.data.list)
              setUserCheck(true)
            }
            else {
              setUserStatus(false)
              // setMemo('New: User Account')
              setUserCheck(true)
            }
          }
          else{
            return
          }
      }
      else{
        setMemo('Error: Minter Account Not Created')
      }

    }
    else{
      setMemo('Error: Account Not Created')
    }
   

  }


  //console.log(usercheck, userstatus)

  return (
    <>
      
      <WebbDividerSmall/>
      <div className='mb-2'></div>

      {/* info */}
      <div className=''>
        <p className='text-normal text-bold m-0 mx-3'>Checkout</p>
        <p className='mx-3 text-small'>Login with Your BharatID Account Email to continue</p>
        <p className='mx-3 text-small d-none'>Login with Your BharatID or Email to continue</p>
        <WebbDividerSmall />
      </div>

      {/* mode */}
      <div className={usercheck ? 'd-none': 'mx-3 d-none'}>
        <p className='text-bold m-0 mb-2'>Select Account and Verify</p>
        <div className="form-check form-check-inline d-none">
          <input className="form-check-input" type="radio" value="bharatid" 
            onChange={() => setMode('bharatid')}
            checked={mode =='bharatid' ? true : false}></input>
          <label className="form-check-label">BharatID</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" value="mail" 
            onChange={() => setMode('mail')}
            checked={mode =='mail' ? true : false}></input>
          <label className="form-check-label">Email</label>
        </div>
        <div className="form-check form-check-inline d-none">
          <input className="form-check-input" type="radio" value="mobile" disabled></input>
          <label className="form-check-label">QR + Mobile</label>
        </div>
        <WebbDividerSmall />
      </div>

      {/* user check */}
      <div className={usercheck ? 'd-none': 'mx-3'}>
        
        <div className='mb-3'>
          <label className="form-label text-small">Enter Your {mode=='bharatid' ? 'BharatID' : 'Email'}</label>
          <input 
            type="text" 
            className="form-control height-md"
            style={{fontSize: '0.9rem', height: '2.7rem'}}
            value={offer?.user?.mail}
            
            // onChange={({ target }) => {handleChange("user", target.value); }}
            disabled={true}
            placeholder={mode=='bharatid' ? '9999000000000123' : 'user@bharat.id'} 
          />
        </div>

        <div className={authcodestatus ? 'mb-3' : 'd-none'}>
          <label className="form-label text-small">Enter One Time Passcode (OTP)</label>
          <input 
            type="email" 
            className="form-control height-md"
            style={{fontSize: '0.9rem', height: '2.7rem'}}
            value={authcode}
            onChange={({ target }) => {SetAuthCode(target.value); }}
            disabled={loader || submit}
            placeholder="123456" 
          />
        </div>

        <WebbDividerSmall />
        <div className={'mb-3'}>
          <div className='d-flex justify-content-start'>
            <div className={authcodestatus ? 'd-none' : ''}>
              <button type='submit'
                className='btn btn-primary btn-sm rounded-xx hidark border-none text-small'
                onClick={() => handleCodeCreate()}
                disabled={loader || submit || done }
              > 
                Get OTP
              </button>
            </div>
                      
            <div className={authcodestatus ? '' : 'd-none'}>
              <button type='submit'
                className='btn btn-primary btn-sm rounded-xx hidark border-none text-small'
                onClick={() =>{ handleUserAccount();setMemo('')}}
                disabled={loader || submit || done || authcode == '' || authcode.length !== 6}
              > 
                Verify OTP
              </button>
            </div>
          </div>
          <p className='text-small mt-4'>{memo}</p>
          <WebbDividerMedium />
        </div>

      </div>

      {/* user account cta */}
      <div className={usercheck && !userstatus ? 'd-none': 'd-none'}>
        <WebbDividerSmall />
        <div className=''>
          <p className='text-bold m-0 mb-2'>{data?.user || '******'}</p>
          <p className='mb-1'>An account with this email could not be located.</p>
          <a className='' href='https://user.bharat.id' target='_blank'>Please create an account here</a>
        
          <WebbDividerSmall />
          <WebbDividerSmall />
          <p className='mb-1 cursor text-primary' onClick={()=>window.location.reload()}>Try with another email</p>
        </div>
        
      </div>
      
      {/* user account new */}
      <div className={usercheck && !userstatus ? 'mx-3': 'd-none'}>
        <p className='text-bold m-0 mb-2'>{data?.user || '******'}</p>
        <p className='mb-4 mt-2 text-danger d-none'>Account Not Found</p>

        <p className='mb-1'>Please Enter Details</p>
        <WebbDividerSmall />
        <div className='mb-3'>
          <label className="form-label text-small">Full Name</label>
          <input 
            type="text" 
            className="form-control height-md"
            style={{fontSize: '0.9rem', height: '2.7rem'}}
            value={data?.name}
            onChange={({ target }) => {handleChange("name", target.value); }}
            disabled={loader}
            placeholder={'user name'} 
          />
        </div>
        <div className='mb-3'>
          <label className="form-label text-small">Mobile Number</label>
          <input 
            type="text" 
            className="form-control height-md"
            style={{fontSize: '0.9rem', height: '2.7rem'}}
            value={data?.mobile}
            onChange={({ target }) => {handleChange("mobile", target.value); }}
            disabled={loader}
            placeholder={'9876540123'} 
          />
        </div> 
        <div className='mb-3 d-none'>
          <label className="form-label text-small">Mail</label>
          <input 
            type="text" 
            className="form-control height-md"
            style={{fontSize: '0.9rem', height: '2.7rem'}}
            value={data?.user}
            onChange={({ target }) => {handleChange("user", target.value); }}
            disabled={true}
            placeholder={'user@bharat.id'} 
          />
        </div> 

        <WebbDividerSmall />
        <button 
          className='btn btn-primary text-small rounded-xx px-4'
          style={{width: 'auto'}}
          disabled={data.name.length < 3 || data.mobile.length != 10 }
          onClick={()=> CreateNewAccount()}
        >Continue</button>

        <p className='mt-4 text-small text-primary'>{memo}</p>

      </div>

      {/* user account */}
      <div className={usercheck && userstatus ? 'mx-3': 'd-none'}>
        <p className='text-bold m-0 mb-2'>User Account</p>
        
        <p className='m-0'>Name: {user?.name || '******'}</p>
        <p className='m-0'>Email: {user?.mail || '******'}</p>
  
      </div>


      {/* rates */}
      <div className={usercheck && userstatus ? 'mx-3': 'd-none'}>
        <WebbDividerMedium />
        <p className='text-bold m-0 mb-2'>Available Offers</p>
        <div className={rates.length > 0 ? 'back-color-wite border rounded' : 'd-none'}>
          {rates && rates.map((item, x) => (item.active ?
          <div className='' key={x} >
            <div className='d-flex m-0 p-2 px-3 mt-1 cursor' onClick={() => handleUserOfferSelect(item.user)} >
              <div className={` ${item.status ? 'text-color-success' : 'text-color-tint'}`}>
                <span className='text-normal align-middle'>
                  <i className={`${item.status ? 'bx bxs-check-circle' : 'bx bxs-circle'}`}></i>
                </span>
              </div>
              <div className='ms-2'>
                <p className='m-0'>{item?.meta?.name || '******'}</p>
                <p className='m-0 text-small d-none'>{item?.number || '******'}</p>
              </div>
              <div className='ms-auto text-end'>
                <span className=''>{item?.rate?.nmbr || '******'}</span>
                <span className='ms-1'></span>
                <span className='text-small text-uppercase'>{item?.rate?.tick || '******'}</span>
              </div>
            </div>
            <div className={x < rates.filter(x=>x.live).length-1 ? 'border-bottom': ''}></div>
          </div>
          :''))}
        </div>

        <WebbDividerMedium />
        <div className={''}>
          <button type='submit'
            className='btn btn-primary btn-sm rounded-xx border-none text-small'
            // data-bs-toggle='modal' data-bs-target={'#modCheckout'}
            style={{width: 'auto'}}
            onClick={() => handleCheckout()}
            disabled={loader || submit || done || !checkoutstatus}
          ><span className='mx-3'>Checkout</span></button>
        </div>
      </div>


      {/* action */}
      <div className='mx-3'>
        <WebbDividerSmall/>

        {/* user */}
        <WebbDividerMedium />
        {/* PassCode */}
        <div className='mb-3'>
          <div className={accountStatus ? 'mb-3': 'd-none'}>
            <label className="form-label text-small">Enter Your Account Passcode</label>
            <input 
              type="password" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={code}
              onChange={({ target }) => {setCode(target.value); }}
              disabled={loader || submit}
              placeholder="123456..." />
          </div>           
        </div>

        {/* action */}
        <WebbDividerSmall />
        <div className={accountStatus ? 'mb-3': 'd-none'}>
          <div className='d-flex justify-content-start'>
            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='submit'
                className='btn btn-primary btn-sm rounded-pill hidark border-none text-small'
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>

            <div className='mx-2'></div>

            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='button'
                className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
                onClick={() => handleDecline()}
              >
                Decline
              </button>
            </div>            
          </div>

          <WebbDividerMedium />
        </div>
      </div>


      {/* modal checkout */}
      <div className={`container ${modalCheckoutStatus ? '' : 'd-none'}`}>
        <div className='row'>
          <div className='col'>
            <div className="modal-overlay" >
                
              <div className="modal-content rounded-xd"  onClick={(e) => e.stopPropagation()}>
                
                {/* modal header */}
                <div className='d-flex d-none'>
                    <div className=''>
                      <p className='text-normal m-0'>{'Checkout'}</p>
                    </div>
                    
                    <div className='ms-auto d-none'>
                      <button type='button' className='btn-close text-small'
                        onClick={() => modalCheckoutClose()}
                      ></button>
                    </div>
                </div>

                {/* modal content - intro */}
                <div className='modal-body border-none  p-3'>
                  <div className='text-center'>
                    <p className={`text-normal m-0 ${commerce ? 'd-none' : ''}`}>{'Checkout'}</p>
                  </div>
                  <WebbDividerSmall />  

                  <div className=''>
                    <div className='media-banner'>
                      <img className='rounded-xd' src={item?.media?.link || '******'} alt='...'></img>
                    </div>
                  </div>

                  <WebbDividerSmall />
                  <div className={`text-center ${commerce ? '' : ''}`}>
                    <p className='text-small m-0 d-none'>{'Order Details'}</p>
                    <p className='text-lead text-bold m-0'>{item?.meta?.name || '******'}</p>
                    <p className='m-0 d-none'>ID: {item?.webx?.number || '******'}</p>
                  </div>

                  <div className='mb-2'></div>
                  <div className={`text-center ${commerce ? 'd-none' : ''}`}>
                    <p className='m-0 '>{'Order Value'}</p>
                    <p className='text-bold m-0'>
                      <span className='text-header-sm'>{rates.find(x=>x.status)?.rate?.nmbr || '0'}</span>
                      <span className='ms-1'></span>
                      <span className='text-uppercase'>{rates.find(x=>x.status)?.rate?.tick || ''}</span>
                    </p>
                  </div>

                  <WebbDividerSmall />
                  <p className='m-0 text-center'>{memo}</p>
                </div>

                {/* action */}
                <div className={`p-3 ${commerce ? 'd-none' : ''}`}>
                  <button className='btn btn-primary text-small rounded-xx text-nowrap w-100' 
                    onClick={()=>{modalCommerceShow(); handleCommerce() }}
                  >
                    {'Continue to Payment'}
                  </button>

                  <div className='mb-2'></div>
                  <button className='btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100' 
                    onClick={() => modalCheckoutClose() }
                  >
                    {'Cancel'}
                  </button>
                </div>

                {/* footer */}
                <div className={`p-3 ${commerce ? '' : 'd-none'}`}>
                  <button className='btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100' 
                    onClick={() => modalCheckoutClose() }
                    >
                    {'Close'}
                  </button>  
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>


      {/* modal - ecom */}
      <div className={`container ${modalCommerceStatus ? '' : 'd-none'}`}>
        <div className='row'>
          <div className='col'>
            <div className="modal-overlay" onClick={() => { modalCommerceClose(); modalTransactionOpen()  }}>
              <div className="modal-content rounded-xd" onClick={(e) => e.stopPropagation()}>
                        
                {/* modal header */}
                <div className='d-flex p-3 d-none'>
                  <div className=''>
                    <p className='text-normal m-0'>{'Add Funds'}</p>
                  </div>
                  <div className='ms-auto'>
                    <button type='button' className='btn-close text-small'
                      onClick={() => modalCommerceClose()}
                    ></button>
                  </div>
                </div>
                        
                {/* modal content */}
                <div className={`modal-body border-none p-3 ${commerce ? '' : ''}`}>
                  <div className='text-center'>
                    <p className='text-normal m-0'>{'Payment'}</p>
                  </div>

                  <WebbDividerSmall /> 
                  <div className='text-center'>
                    <p className='m-0 '>{'Amount'}</p>
                    <p className='text-bold m-0'>
                      <span className='text-header-sm'>{rates.find(x=>x.status)?.rate?.number || '0'}</span>
                      <span className='ms-1'></span>
                      <span className='text-uppercase'>{rates.find(x=>x.status)?.rate?.ticker || ''}</span>
                    </p>

                    <WebbDividerSmall /> 
                    <p className='text-normal m-0'>{'Please Wait...'}</p>
                  </div>

                  <div className={`mb-3 text-center ${commerce ? 'd-none' : 'd-none'}`}>
                    <WebbDividerSmall /> 
                    <label className="form-label text-small">Enter OTP (Passcode)</label>
                    <input type="text" 
                      className="form-control height-md text-center"
                      style={{fontSize: '0.9rem', height: '2.7rem'}}
                      value={data.code}
                      onChange={({ target }) => {handleChange("code",target.value); }}
                      disabled={loader }
                      placeholder={''} 
                    />
                  </div>

                  {/* success */}
                  <div className={`text-center ${commerce ? '' : 'd-none'}`}>
                    
                    <div className='mb-2'></div>
                    <p className='text-normal text-bold m-0'>Transaction Success</p>
                    <Lottie  options={defaultOptions} height={200} width={200} />
                    
                    <div className='mb-2'></div>
                    <p className='text-small'>Please Wait...</p>
                  </div>
                  

                </div>

                {/* action */}
                <div className={`p-3 ${commerce ? 'd-none' : 'd-none'}`}>
                  <WebbDividerMedium />
                  <button className='btn btn-primary text-small rounded-xx text-nowrap w-100' 
                    disabled={loader || data.code == "" || data.code.length !== 6}
                    onClick={() => handleCommerce()}
                  >
                    {'Submit'}
                  </button>

                  <div className='mb-2'></div>
                  <button className='btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100' 
                    
                    onClick={() => modalCheckoutClose() }
                  >
                    {'Cancel'}
                  </button>
                </div>


                {/* footer */}
                <WebbDividerSmall />

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal - ecom - cancel */}
      <div className={`container ${modalCancelTransaction ? '' : 'd-none'}`}>
        <div className='row'>
          <div className='col'>
            <div className="modal-overlay" >
              <div className="modal-content rounded-xd" onClick={(e) => e.stopPropagation()}>
                        
                {/* modal header */}
                
                        
                {/* modal content */}
                <div className={`modal-body border-none p-3`}>
                  <div className='text-center'>
                    <p className='text-normal m-0'>{'Cancel Transaction'}</p>
                  </div>

                  <p className='text-center mt-3'>Are You Sure to Cancel Transaction</p>
                  <div className='mt-5'>
                      <div className='d-flex'>
                          <div className='me-auto'><button onClick={ ()=> modalTransactionClose() } className='btn btn-outline-danger rounded-xx'>Yes</button></div>
                          <div className='text-end'><button className='btn btn-outline-success rounded-xx' onClick={()=>{ContinueOrderPurchase(); modalCheckoutShow()}}>No</button></div>
                      </div>
                  </div>
                
                  <WebbDividerSmall /> 

                </div>

                {/* action */}
                <WebbDividerMedium />
                <div className={`p-3 ${commerce ? 'd-none' : 'd-none'}`}>
                  
                  <div className='mb-2'></div>
                  <button className='btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100' 
                    
                    onClick={() => modalTransactionClose() }
                  >
                    {'Cancel'}
                  </button>
                </div>

                {/* footer */}
                <WebbDividerSmall />

              </div>
            </div>
          </div>
        </div>
      </div>


      {/* modal - minter */}
      <div className={`container ${modalMinterStatus ? '' : 'd-none'}`}>
        <div className='row'>
          <div className='col'>
            <div className="modal-overlay" >
              <div className="modal-content rounded-xd"  onClick={(e) => e.stopPropagation()}>
                {/* modal header */}
                <div className=""></div>

                {/* modal content */}
                <div className='modal-body border-none p-3'>
                  <div className='text-center'>
                    <p className={`text-normal m-0`}>{'Mint Asset'}</p>
                  </div>

                  <WebbDividerSmall />  
                  <div className='media-banner'>
                    <img className='rounded-xd' src={item?.media?.link || '******'} alt='...'></img>
                  </div>

                  <WebbDividerSmall />
                  <div className={`text-center`}>
                    <p className='text-small m-0 d-none'>{''}</p>
                    <p className='text-lead text-bold m-0'>{item?.meta?.name || '******'}</p>
                    <p className='m-0'>ID: {item?.webx?.number || '******'}</p>
                  </div>

                  <div className={`text-center ${mintstart && minterstatus ? 'd-none' : 'd-none'}`}>
                    <WebbDividerMedium />
                    <label className="form-label text-small">Enter Your Minter Account Passcode</label>
                    <input type="text" 
                      className="form-control height-md text-center"
                      style={{fontSize: '0.9rem', height: '2.7rem'}}
                      value={data.secret}
                      onChange={({ target }) => {handleChange("secret",target.value); }}
                      disabled={ false }
                      placeholder={''} 
                    />
                    <p className={`mt-1 mb-0 text-color-error text-small ${minterstatus ? '' : 'd-none'}`}>Incorrect Account Passcode</p>
                  </div>

                </div>

                {/* action minter */}
                <div className={`p-3 ${minterstatus && mintstart && !mintdone ? '' : 'd-none'}`}>
                  
                  <WebbDividerMedium/>
                  <p className="text-center text-small m-0">{memo}</p>
                  <div className='mb-2'></div>
                  <p className="text-center text-color-error m-0">{'Do not close the window'}</p>

                  <WebbDividerSmall />
                  <div className="progress" role="progressbar" aria-label="..." 
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" 
                    style={{width: "100%", height: '0.5rem'}}>
                    <div className="progress-bar progress-bar-striped progress-bar-animated" 
                      style={{width: "100%", height: '0.5rem'}}
                    ></div>
                  </div>
                  
                </div>
                <div className={`p-3 ${mintstart && mintdone ? '' : 'd-none'}`}>
                  <p className="text-center text-bold m-0">{'Congratulations'}</p>
                  <WebbDividerSmall />

                  <Lottie  options={defaultOptions} height={200} width={200} />
                  <p className="text-center m-0">{'Mint Successful'}</p>
                </div>

                <div className={`p-3 ${mintstart && minterstatus ? 'd-none' : ''}`}>

                  <button className='btn btn-primary text-small rounded-xx text-nowrap w-100' 
                    disabled = {data.secret == ""}
                    onClick={()=>{navigate(`/mx/${assetTrxnId}`) }}
                  >
                    {'Mint Now'}
                  </button>
                  <div className='mb-2'></div>
                  <button className='btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100' 
                    onClick={() => {modalMinterClose(); window.location.reload(true)} }
                  >
                    {'Skip'}
                  </button>                  
                  <WebbDividerSmall />
                </div>

                {/* modal footer */}
                <div className="text-center text-small text-primary ">{memo}</div>                


              </div>
            </div>
          </div>
        </div>
      </div>

      {/* loader */}
      <div className={`mx-3 ${loader && submit ? 'd-none' : 'd-none'}`}>
        <p>{memo}</p>
      </div>

      {/* done */}
      <div className={`mx-3 ${!loader && submit ? 'd-none' : 'd-none'}`}>
        <p className={done ? '' : 'd-none'}>
          <i className='bx bxs-check-circle text-color-success'></i>
          <span className='ms-1'>Success. You can close this window.</span>
        </p>
        <p className={done ? 'd-none' : ''}>
          <i className='bx bxs-error-circle text-color-danger'></i>
          <span className='ms-1'>Failed</span>          
        </p>
        <p className={done ? 'd-none' : ''} onClick={() => window.location.reload}>
          <span className='p-2 back-color-tint'>Please Try Again</span>
        </p>
      </div>      

    </>
  )
}
