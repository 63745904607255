import React, { useState } from 'react';
import FormNeeded from '../../webx/form-needed';

const PersonalInfoForm = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    // Handle form submission here
    // console.log(formData);
    if(formData.name && formData.email && formData.mobile){
        props.basicInfo(formData)
    }
    else{
        alert("Please provide input to every field")
    }
    
  };

  return (
    <>
    <h6 className='text-secondary mt-4 mb-3'>Provide Basic Info</h6>
    <div >
      <div>
        <label className=''>Name <FormNeeded/> </label>
        <input
          className='form-control'
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label className='mt-3'>Email <FormNeeded/></label>
        <input
          className='form-control'
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label className='mt-3'>Mobile <FormNeeded/></label>
        <input
          className='form-control'
          type="text"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange}
        />
      </div>
      <button  onClick={()=>handleSubmit()} className='btn rounded-xx btn-primary text-small float-end mt-3 w-100 ' type="submit">Next</button>
    </div>
    </>
  );
};

export default PersonalInfoForm;
