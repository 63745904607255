import axios from "axios";
import services from '../data.services/data-services-snbx.json'

const base = (services.data.find(x => x.code == 'offers')).link

// -----------------

export const IndividualOffersDetails = async item => {

  const basx = base + '/assets/offer/details/individual'
  const head = {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_WEBB_SITE_NMBR
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const IndividualOffersStatusSet = async item => {

    const basx = base + '/assets/individual/offers/status/set'
    const head = {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_WEBB_SITE_NMBR
    }
    
    const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }
  
    var result
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }