// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'

import {
  TransferDataDecline,
  TransferDataSubmit
} from '../../services/srvc-transfers-data-realm'
import { UserAssetList } from '../../services/srvc-userx-realm'

import BaseMedia from '../../data.media/tokenasset.png'
import { TAXO_CATEGORIES } from '../../utils'
import { getDummyImg } from '../../utils/utils'

const list = [
  'Identity',
  'Finance',
  '',
  'Social',
  'Academics',
  '',
  'Assets',
  'Work',
  'Health'
]

export default function TransferActionModule (props) {
  const asset = { form: 'www' }

  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [form, setForm] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [item, setItem] = useState('')
  const [formData, setformData] = useState([])

  const [creds, setCreds] = useState([])
  const [sort, setSort] = useState([])
  const [data, setData] = useState([])
  const excludeTaxxo = [
    '1435bad88b624449aa04734c500fc17d5',
    '221e45dd818c430e958c1c822311c8167'
  ] //age,bank stmt
  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        setItem(props.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setForm(false)
        if (formData.filter(x => x.list.find(s => s?.select)).length > 0)
          setForm(true)
      }
      fetchData()
    }
  }, [formData])

  useEffect(() => {
    const fetch = async () => {
      const result = await UserAssetList({ data: { user: item.debt.item } })
      if (result?.data?.stat) {
        const assetList = result.data.data.list
        const transformedItems = item.vars.map(({ taxxon, code }) => {
          const foundList = assetList
            .filter(asset => asset.taxxon === taxxon)
            .map(asset => ({ ...asset, select: false }))
          const domain = TAXO_CATEGORIES.find(
            domain => domain.mainCode === parseInt(code[0])
          )
          return { domain: domain.domain, taxxon, list: foundList }
        })
        setformData(transformedItems)
        const sortx = [
          ...new Set(transformedItems.map(item => item.taxxon.substring(0, 1)))
        ] // [ 'A', 'B']

        console.log('sort', sortx)

        setSort(
          Array.from(sortx, x => {
            return { code: x, name: list[x - 1] }
          })
        )
      }
    }
    if (item) {
      fetch()
    }
  }, [item])

  const mediaCheck = async link => {
    const img = new Image()
    img.src = link
    return new Promise(resolve => {
      img.onerror = () => resolve(false)
      img.onload = () => resolve(true)
    })
  }

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const handleDecline = async () => {
    setLoading(true)
    setSubmit(true)

    // create vars
    const vars = Array.from(item.vars, x => {
      return { ...x, data: 'xxxxxx' }
    })

    //update transfer
    const datx = { item: id, vars }
    const result = await TransferDataDecline({ data: datx, srvc: '******' })
    if (result?.data?.status == 7) {
      // console.log('done')
      setDone(true)
      window.location.reload()
    }

    setLoading(false)
  }

  const handleSelect = async (taxxon, number) => {
    const selectx = [...formData]
    const selected = selectx
      .find(x => x.taxxon === taxxon)
      ?.list.find(item => item.number === number)
    selected.select = !selected.select
    setformData(selectx)
  }

  const handleSubmit = async () => {
    // e.preventDefault()
    setLoading(true)
    // setSubmit(true)
    const varx = item.vars.map(item => {
      const obj = formData.find(x => x.taxxon === item.taxxon)
      const asset = obj.list.filter(x => x.select)
      return { ...item, data: asset }
    })

    //update transfer
    const datx = {
      item: id,
      vars: varx
    }
    // return console.log('datax', datx)
    const result = await TransferDataSubmit({ data: datx, srvc: '******' })
    // console.log(result)

    if (result?.data?.status === 6) {
      setDone(true)
      window.location.reload()
    }
    setLoading(false)
  }
  const CredCards = item => {
    return (
      <div className='mb-2'>
        <div
          className='d-flex cursor justify-content-start'
          onClick={() => handleSelect(item?.taxxon, item?.number)}
        >
          <div className=''>
            <i
              className={`text-lead ${
                item?.select
                  ? 'bx bxs-check-circle text-color-success'
                  : 'bx bx-circle text-color-tint'
              }`}
            ></i>
          </div>
          <div className='ms-2'>
            <p className='text-bold m-0'>{item?.meta.name}</p>
            <p className='m-0 mb-1'>{item?.number}</p>
            {/* {item?.data && <p className='m-0 mb-1'>{item?.data?.age}</p>} */}
            <p className='text-small text-color-tone m-0 d-none'>
              {item?.creator.name}
            </p>
            <div className='media-card cursor'>
              <img
                width={100}
                height={100}
                src={item?.media?.link}
                className='img rounded-xd'
                alt='...'
              ></img>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const updateUser = async fields => {}

  return (
    <>
      <WebbDividerSmall />
      <div className='mb-2'></div>

      {/* credit */}
      <p className='m-0 mx-3 text-normal'>Select Data and Submit</p>
      <p className='mx-3 text-small'>Please select and confirm the transfer:</p>

      {/* data fields */}
      <div className='mx-3'>
        <div className='ids' style={{ height: '400px', overflow: 'scroll' }}>
          {sort &&
            sort.map((item, z) => (
              <>
                <p className='text-small text-bold text-uppercase text-color-next mb-2'>
                  {item.name}
                </p>

                {formData &&
                  formData.map((item, i) =>
                    item.taxxon.substring(0, 1) == sort[z].code ? (
                      <div key={item.taxxon}>
                        {item.list.map((item, x) => (
                          <CredCards {...item} key={item.number} />
                        ))}
                      </div>
                    ) : (
                      ''
                    )
                  )}
                <div className='border-bottom mb-3'></div>
              </>
            ))}
        </div>

        {!formData?.length && 'Data Not Found'}
        <WebbDividerSmall />
        <div
          className={
            formData.length > 0 ? 'd-flex justify-content-start' : 'd-none'
          }
        >
          <div className={`${submit ? 'd-none' : ''}`}>
            <button
              type='button'
              className='btn btn-primary btn-sm rounded-pill hirich border-none text-small'
              onClick={() => handleSubmit()}
              disabled={!form}
            >
              Submit
            </button>
          </div>

          <div className='mx-2'></div>

          <div className={`${submit ? 'd-none' : ''}`}>
            <button
              type='button'
              className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
              onClick={() => handleDecline()}
            >
              Decline
            </button>
          </div>
        </div>

        <WebbDividerMedium />
      </div>

      {/* action */}

      {/* loader */}
      <div className={`mx-3 ${loading && submit ? '' : 'd-none'}`}>
        <p>Please Wait...</p>
      </div>

      {/* done */}
      <div className={`mx-3 ${!loading && submit ? '' : 'd-none'}`}>
        <p className={done ? '' : 'd-none'}>
          <i className='bx bxs-check-circle text-color-success'></i>
          <span className='ms-1'>Success</span>
        </p>
        <p className={done ? 'd-none' : ''}>
          <i className='bx bxs-error-circle text-color-danger'></i>
          <span className='ms-1'>Failed</span>
        </p>
        <p
          className={done ? 'd-none' : ''}
          onClick={() => window.location.reload}
        >
          <span className=''>Please Try Again</span>
        </p>
      </div>
    </>
  )
}
