import React, { useState } from 'react';
import FormNeeded from '../../webx/form-needed';

const CreditScoreForm = (props) => {
  const [formData, setFormData] = useState({
    age: 23,
    annualIncome: 50000,
    monthlyInhandSalary: 4000,
    numBankAccounts: 3,
    numCreditCard: 2,
    interestRate: 5,
    numOfLoan: 3,
    changedCreditLimit: 11,
    outstandingDebt: 1000,
    creditHistoryAge: 250,
    amountInvestedMonthly: 20000,
    monthlyBalance: 1000,
  });

  const [loader, setLoader] = useState(true)
  const [score, setScore] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setScore(null)
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const checkCreditScore=async()=>{
    setLoader(true)
    try {
        var datx = {
            "model":"payday_loan",
            "data":{
                Age: formData.age,
                Annual_Income: formData.annualIncome,
                Monthly_Inhand_Salary: formData.monthlyInhandSalary,
                Num_Bank_Accounts: formData.numBankAccounts,
                Num_Credit_Card: formData.numCreditCard,
                Interest_Rate: formData.interestRate,
                Num_of_Loan: formData.numOfLoan,
                Changed_Credit_Limit: formData.changedCreditLimit,
                Outstanding_Debt: formData.outstandingDebt,
                Credit_History_Age: formData.creditHistoryAge,
                Amount_invested_monthly: formData.amountInvestedMonthly,
                Monthly_Balance: formData.monthlyBalance,
            }
        }
        
        const response = await fetch('https://7hgodc4o4rb7oaw226oqwpjlae0rolfq.lambda-url.ap-south-1.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(datx),
        });
  
        if (!response.ok) {
          // If the response is not OK, throw an error
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json(); 
        // console.log(data.turescore)
        setScore(data?.turescore)
        
      } catch (error) {
        console.error('Error:', error);
        setScore('Error')
       
      }

      setLoader(false)

  }



  const handleSubmit = (e) => {
    //e.preventDefault();
    // console.log('Form data submitted:', {creditscore: score});
    if(score)
    {
      const concatenatedString = Object.entries(formData)
      .map(([key, value]) => `${key}: ${value}`) // format each key-value pair as "key: value"
      .join(', '); 
        props.creditScoreInfo({creditscore: score, input:concatenatedString})
    }
    else{
        alert("credit score not found")
    }
    
    // You can now send this data to an API or process it as needed
  };

  return (
    <div >
      <label className='w-100'>
        Age: <FormNeeded />
        <input 
          className='form-control'
          type="number"
          name="age"
          value={formData.age}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 mt-2'>
        Annual Income: <FormNeeded />
        <input
          className='form-control'
          type="number"
          name="annualIncome"
          value={formData.annualIncome}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 mt-2'>
        Monthly Inhand Salary:<FormNeeded />
        <input
          className='form-control'
          type="number"
          name="monthlyInhandSalary"
          value={formData.monthlyInhandSalary}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 d-none mt-2'>
        Number of Bank Accounts: <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="numBankAccounts"
          value={formData.numBankAccounts}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 d-none mt-2'>
        Number of Credit Cards:<FormNeeded />
        <input
        className='form-control'
          type="number"
          name="numCreditCard"
          value={formData.numCreditCard}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 mt-2'>
        Interest Rate (%): <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="interestRate"
          value={formData.interestRate}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 mt-2'>
        Number of Loans: <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="numOfLoan"
          value={formData.numOfLoan}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 d-none mt-2'>
        Changed Credit Limit (%): <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="changedCreditLimit"
          value={formData.changedCreditLimit}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 mt-2'>
        Outstanding Debt: <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="outstandingDebt"
          value={formData.outstandingDebt}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 d-none mt-2'>
        Credit History Age (in months): <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="creditHistoryAge"
          value={formData.creditHistoryAge}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 mt-2'>
        Amount Invested Monthly: <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="amountInvestedMonthly"
          value={formData.amountInvestedMonthly}
          onChange={handleChange}
        />
      </label>

      <label className='w-100 mt-2'>
        Monthly Balance: <FormNeeded />
        <input
        className='form-control'
          type="number"
          name="monthlyBalance"
          value={formData.monthlyBalance}
          onChange={handleChange}
        />
      </label>
      <div className={ loader? "d-none": ' border p-3 w-100 bg-white mt-3 rounded-xd'}>
        <p className='m-0 fw-bold text-lead'>Score - {score}</p>
      </div>
    
    
      <button onClick={()=>checkCreditScore()}   className={score == null ?'btn rounded-xx btn-primary text-small float-end mt-3 w-100  height-md ':'d-none' }  type="submit">Check Score</button>
      
      <button onClick={()=>handleSubmit()}   className= {score != null ? 'btn rounded-xx btn-primary text-small float-end mt-3 w-100  height-md':'d-none'}  type="submit">Submit</button>

      {/* <button type="submit">Submit</button> */}
    </div>
  );
};

export default CreditScoreForm;
