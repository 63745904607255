// main
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xz'

import WebbDateTimeMedium from '../content/webx/webb-datetime-md'
import WebbDividerMedium from '../content/webx/webb-divider-md'

import TransferActionModule from '../content/transfers/transfers-action'
import TransferDetailsModule from '../content/transfers/transfers-details'
import TransferLoaderModule from '../content/transfers/transfers-loader'
import TransferStatusModule from '../content/transfers/transfers-status'

import { UserDetails } from '../services/srvc-user-realm'
import QrScanDetails from '../content/qrscan/qr-scan-details'

export default function QrScan () {
  const metadata = {
    name: 'Authorize Data Transfer',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const asset = { user: '******' }
  const { email } = useParams()

  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState()

  const [item, setItem] = useState()
  const [user, setUser] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        const datx = { data: { user: email }, srvc: '1234' }
        const res = await UserDetails(datx)
        console.log('r', res)
        setStatus({ code: res?.data?.code })
        setItem(res?.data?.data)

        setLoading(false)
      }
      fetchData()
    }
  }, [email])

  if (loading) return <TransferLoaderModule />

  if (!loading && status && ![200, 201, 415].includes(status.code)) {
    return 'Error'
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{ size: 'mini', visible: false, data: <> </> }}
        media={{ size: 'xtra', data: <></> }}
        content={{
          size: 'xtra',
          data: (
            <>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-4 d-none d-md-block back-color-wite'></div>
                  <div className='col-md-4 back-color-wite'>
                    <QrScanDetails data={item} />
                    <WebbDividerMedium />
                  </div>

                  <div className='col-md-4 back-color-lite d-md-none'>
                    {/* <TransferActionModule
                      data={item}
                      approver={user}
                      source='link'
                    /> */}

                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />

                    <div
                      className='text-small text-color-tint mx-3'
                      style={{ position: 'relative', bottom: '1.5rem' }}
                    >
                      <WebbDateTimeMedium />
                      <p className='text-small text-color-next m-0 mb-1'>
                        <span className='text-mini'>
                          <i className='bi bi-circle-fill'></i>
                        </span>
                        <span className='ms-1'>
                          Testnet Transaction for Demo
                        </span>
                      </p>
                      Powered by{' '}
                      <span className='text-normal text-bold'>BharatID</span>{' '}
                      <span className='ms-3'></span>
                      <span>Terms</span>
                      <span className='mx-1'></span>
                      <span>Privacy</span>
                    </div>
                  </div>

                  <div
                    className='col-md-1 back-color-lite d-none d-md-block'
                    style={{ minHeight: '100vh' }}
                  ></div>
                </div>
              </div>
            </>
          )
        }}
        footer={{
          size: 'medium',
          data: (
            <>
              <div className='text-center back-color-lite fixed-bottom pt-2 mb-1 d-none'>
                www.bharat.id
              </div>
            </>
          )
        }}
      ></ContentFormat>
    </>
  )
}
