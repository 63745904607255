// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbLoaderSmall from '../webx/webb-loader-sm'


export default function FormatLoaderModule (props) {

  const asset = { form: 'ww' }

  const {id} = useParams()
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

      }
      fetchData()
    }
  }, [id, props])

  // if (loading) return <></>

  return (
  <>
    <div className='back-color-lite text-center' style={{height:'100vh'}}>
      <WebbDividerMedium />
      <WebbDividerMedium />
      <WebbLoaderSmall />
      <WebbDividerMedium />
      
      <p className=''>Please Wait...</p>

      <WebbDividerMedium />
      <div className='text-color-tone text-small'>
        <p className='m-0'>Fetch Format Details</p>
        <p className='m-0'>{id}</p>
      </div>

      {/* footer */}
      <div className='text-color-tone text-center fixed-bottom'>
        <p className='m-0 text-small'>BharatID</p>
        <p className='m-0 text-mini'>{'Website • Terms • Privacy Policy'}</p>
        <WebbDividerMedium />
      </div>


    </div>
  
  </>
  )

}