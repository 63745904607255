// transfers
import { useEffect, useState } from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'

import { TransferCreate } from '../../services/srvc-transfers-data-realm'
import WebbTrackerMedium from '../webx/webb-tracker-md'

export default function TmQrScanDetails (props) {
  const asset = { form: 'ww' }

  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const [item, setItem] = useState([])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        // console.log('pr', props?.data)
        setItem(props?.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])
  console.log(item)
  const proceed = async e => {
    //1.create tx and redirect to tx details
    const datax = {
      data: {
        user: {
          name: item?.user?.name,
          mail: item?.user?.mail
        },
        format: item?.item,
        memo: item?.memo,
        consumer: item?.consumer?.item,
        sale: { number: '1', ticker: '******' },
        rate: { number: '0', ticker: '******' }
      }
    }
    setLoading(true)
    const tx = await TransferCreate(datax)
    console.log('tx', tx)
    if (tx?.stat) {
      window.open(tx?.data?.link, '_blank')
      // navigate('/auth/x')
    }
    setLoading(false)
  }
  const DocDetails = () => {
    return (
      <div className={'mx-3'}>
        <WebbDividerMedium />
        <p className='text-bold m-0 mb-2'>Documents</p>
        <div
          className={
            item?.vars?.length > 0 ? 'back-color-wite border rounded' : 'd-none'
          }
        >
          {item &&
            item?.vars?.map((item, x) => (
              <div className='' key={x}>
                <div className='d-flex m-0 p-2 px-3 mt-1'>
                  <div
                    className={` ${
                      item.status ? 'text-color-success' : 'text-color-tint'
                    }`}
                  >
                    <span className='text-normal align-middle'>
                      <i
                        className={`${
                          item.status ? 'bx bxs-check-circle' : 'bx bxs-circle'
                        }`}
                      ></i>
                    </span>
                  </div>
                  <div className='ms-2'>
                    <p className='m-0'>{item?.name || '******'}</p>
                  </div>
                  <div className='ms-auto text-end'>
                    <span className=''>{'******'}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }

  return (
    <>
      <WebbDividerMedium />

      <>
        <div className='d-flex mx-3 align-middle'>
          <div className=''>
            <span className='rounded-xx align-middle'>
              <Jazzicon
                diameter={30}
                seed={jsNumberForAddress(item?.name || Date.now().toString())}
              />
            </span>
          </div>
          <div className='ms-2 align-middle'>
            <p
              className='text-normal text-uppercase text-bold m-0'
              style={{ lineHeight: '1rem' }}
            >
              {item?.consumer?.name || 'Consumer'}
            </p>
            <p className='text-small text-color-tone text-uppercase m-0'>
              {item?.consumer?.mail || '<consumer@mail>'}
            </p>
          </div>
          <span className='ms-2 mt-1 mb-1 text-uppercase text-bold'></span>
        </div>
        <WebbDividerMedium />
        <div className='ms-2'>
          <p
            className='text-normal text-uppercase text-bold m-0'
            style={{ lineHeight: '1rem' }}
          >
            Domain
          </p>
          <p className='text-small text-color-tone text-uppercase m-0'>
            {`${item?.domain} - ${item?.memo}` || '****'}
          </p>
        </div>
        <WebbDividerMedium />
        {item && <DocDetails />}
      </>

      {/* footer */}
      <div
        className='text-small text-color-tint d-none d-md-block mx-3'
        style={{ position: 'fixed', bottom: '1rem' }}
      >
        <p className='text-small text-color-next m-0 mb-1 mt-2'>
          <span className='text-mini'>
            <i className='bi bi-circle-fill'></i>
          </span>
          <span className='ms-1'>Testnet Transaction for Demo</span>
        </p>
        <WebbTrackerMedium />
        Powered by <span className='text-normal text-bold'>BharatID</span>{' '}
        <span className='ms-3'></span>
        <span>Terms</span>
        <span className='mx-1'></span>
        <span>Privacy</span>
      </div>
    </>
  )
}
