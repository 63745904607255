// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'

import { AccountsMinterDetails } from '../../services/srvc-accounts-realm'
import { AccountsMinterStatus, AccountsMinterInit } from '../../services/srvc-accounts-realm'
import { TextReverse } from '../../services/srvc-encr-node'


import { MetadataCreate } from '../../services/srvc-media-realm'

import { TokenDetails } from '../../services/srvc-tokens-realm'
import { TokenMint, TokenClaim, TokenTransfer } from '../../services/srvc-tokens-realm'
import { TransfersAssetSubmit, TransfersAssetDecline, TransfersAssetStatusSet } from '../../services/srvc-transfers-assets-realm'
import { TokensClaimArnd, TokensTransferArnd } from '../../services/srvc-tokens-rand-realm'
import { AssetUnitDataBookSet, AssetUnitDataSaleSet } from '../../services/srvc-assets-units-realm'

export default function CheckoutActionModule (props) {
  
  // console.log(props.data)

  const asset = { form: 'ww' }
  const navigate = useNavigate()
  const { id } = useParams()
  
  const [loading, setLoading] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('')

  const [form, setForm] = useState(false)
  
  const [item, setItem] = useState()
  
  const [accountStatus, setAccountStatus] = useState(false)
  const [account, setAccount] = useState({})
  const [secret, setSecret] = useState('')
  const [code, setCode] = useState('')

  const [number, setNumber] = useState('')
  const [docs, setDocument] = useState(false)
  const [custom, setCustom] = useState('')

  const [media, setMedia] = useState({})
  const [data, setData] = useState({
    name: '', 
    mail: '',
    account: '',
  })

  const [minterstatus, setMinterStatus] = useState(false)
  const [minter, setMinter] = useState({number: "", secret: ""})
  const [mintstart, setMintStart] = useState(false)
  const [mintdone, setMintDone] = useState(false)

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        
        var code = localStorage.getItem("code")
        // console.log(code)
        setCode(code ? code : '' )
        setSecret(code? code : '')

        // token info
        setItem(props?.data)
        var resAccount

        if (props?.data) {
          resAccount = await AccountsMinterDetails ({data: {user: props?.data?.credit?.item|| '******'}})
          // console.log (resAccount)
          setAccountStatus(resAccount?.data?.active ? true : false)
          if (resAccount?.stat) setAccount(resAccount?.data?.account?.number)

        }

        if (props?.data) {
          setData({
            ...data, 
            name: props?.data?.credit?.name || '', 
            mail: props?.data?.credit?.mail|| '',
            account: resAccount?.data?.data?.account?.number || '', 
          })
        }        

        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])   


  // form validation
  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

        setForm(false)
        if (
          data && data.name !== "" &&
          data && data.mail !== "" &&
          code && code !== "" && code.length == 6 &&
          account && account.number !== "" &&
          account && account.secret !== "" 
        )
        setForm(true)

      }
      fetchData()
    }
  }, [id, data, account, secret, code])

 // form validation
 useEffect(() => {
  if (asset) {
    const fetchData = async () => {

      setMemo('')

    }
    fetchData()
  }
}, [secret])


  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }


  const handleDecline = async() => {

    setLoading(true)
    setSubmit(true)

    // create vars
    // const vars = Array.from(formData, x => { return { ...x, data: 'xxxxxx' }})
    
    //update transfer
    const datx = { item: id }
    const result = await TransfersAssetDecline({data: datx, srvc: '******'})

    // console.log(result)

    if (result?.data?.status == 7) {
      // console.log('done')
      setDone(true)
      // return
      window.location.reload()
    }

    setLoading(false)    

  }  


  const handleTokenSubmit = async() => {

    setLoading(true)
    setSubmit(true)
    // setMemo('Please Wait... Metadata Create')
    setMemo('Please Wait... Token Mint')

    // 1 - metadata create
    const metadata = {
      name: item.unit.name,
      description: item.unit.memo,
      image: item.media.link,
      attributes: [
        { asset: item.asset.number },
        { unit: item.unit.number },
        { reference: '******' },
        { vars: [] }, 
        { role: 'user' }
      ]

    } // item.meta.data
    console.log (item.unit.number)
    var metalink = item?.meta?.link || "******"
    if (metalink == "******") {
      setMemo('Please Wait... Token Metadata')
      const metalinx = await MetadataCreate({
        data: { metadata: metadata, asset: item.asset.number, unit: item.unit.number }, 
        srvc: '******'
      })

      metalink = metalinx?.data?.file?.link || '******'
      console.log (metalink) // metalink.data.link  
    }
    if (metalink == '******') {
      setMemo('Failed');
      return
    }

    // 2 - token mint
    var tokenx = item?.meta?.item || "******"
    setMemo('Please Wait... Token Mint')
    if (tokenx == '******') {
      const mintdata = {
        name: item.unit.name,
        memo: `iam.${item.unit.number}.mint.${item.sale.number}.bharatid`,
        tokenuri: metalink,
        count: item.sale.number,
        ticker: item.sale.ticker,
        chain: '416002'
      }
      const tokenz = await TokenMint({data: mintdata})
      tokenx = tokenz?.data?.asset.toString() || '******'
      console.log(tokenx)
    }

    if (tokenx == '******') {
      setMemo('Failed');

      return
    }


    // 3 - user secret
    setMemo('Please Wait... Account Check')
    const secretx = await TextReverse({text: account.secret, secret: code})
    console.log(secretx)

    // 4 - account balance check
    setMemo('Please Wait... Account Maintenance')
    // const accountx = await AccountStatus({data: {account: account.number, chain: '416002'}})
    // console.log (accountx)

    // 5 - token claim
    setMemo('Please Wait... Token Claim')
    const claimdata = {
      account: "",
      mnemonic: secretx.data,
      asset: tokenx,
      count: item.sale.number.toString(),
      memo: `iam.${item.unit.number}.claim.bharatid`,
      chain: '416002'
    }
    const claimx = await TokenClaim({data: claimdata})
    console.log(claimx)
    if (claimx.code == 400) {
      setMemo('Please Wait... Token Claim Failed')
      return
    }

    // 6 - token transfer
    setMemo('Please Wait... Token Transfer')
    const transferdata = {
      account: account.number,
      asset: tokenx,
      count: item.sale.number.toString(),
      memo: `iam.${item.unit.number}.transfer.${item.sale.number}.bharatid`,
      chain: '416002'
    }
    const transferx = await TokenTransfer({data: transferdata})
    console.log(transferx)  

    if (transferx.code == 400) {
      setMemo('Please Wait... Token Transfer Failed')
      return
    }

    if (transferx.data) {
      setDone(true)
      setMemo('Please Wait... Success')
      

    } else {
      setMemo('Please Wait... Failed')
    }

    //checkout status set


    setLoading(false)
  }

  const handleSubmit = async () => {

    setLoading(true)
    setSubmit(true)

    //update transfer
    const datx = {
      item: id
    }
    console.log(datx)
    
    const result = await TransfersAssetSubmit({data: datx, srvc: '******'})
    console.log(result)

    if (result?.data?.status == 6) {
      console.log('done')
      setDone(true)
      // return
      window.location.reload()
    }

    setLoading(false)
  }

  const handleUnitTransfer = async() => {

    setMintStart(true)
    setMemo('Please Wait... Mint Asset')

    // 0: create asset transfer
    // 1: check minter account
    // 2: if no minter account, create and mint, send alert
    // 3: if minter account, get + check account details
    // 4: check minter account status - balance
    // 5: initiate transfer
    // 6: set asset transfer status
    setMemo('Please Wait... 1/5')

    var datx = {user: props?.data?.credit?.item}
    const minterx = await AccountsMinterDetails({ data: datx, client: '', service: '' })
    //console.log (minterx)
    if (minterx.data.account.code !== secret) {
      // alert('Incorrect Account Passcode')
      setMemo('Failed: Incorrect Account Passcode')
      setMinterStatus(false)

      setLoading(false)
      setMintStart(false)
      return
    } 
    setMinterStatus(true)

    setMemo('Please Wait... 2/5')
    
    const mnemonix = await TextReverse( {text: minterx.data.account.secret, secret: secret})
    // console.log(mnemonix)


   
    var bookx = await AssetUnitDataBookSet({ data: {unit: props?.data?.unit?.item, count: '1', mode: 'credit' }, srvc: '******' })
    
    datx = {
      "user":  props?.data?.credit?.item || "******"
    }
    setMemo('Please Wait... 3/5')
    var statusx = await AccountsMinterStatus({ data: datx, client: '', service: ''})
    // console.log(statusx)
    if (!statusx?.data?.status?.mint) {
      // console.log('Please Wait... 3/5')
      var initx = await AccountsMinterInit({ data: datx, client: '', service: ''})
      statusx = await AccountsMinterStatus({ data: datx, client: '', service: ''})
    }

    // console.log(minterx.data.account)
    if (statusx?.data?.status?.mint) {
      setMemo('Please Wait... 4/5')
      datx = {
        "account": {"number": minterx?.data?.account?.number, "secret": mnemonix.data},
        "number": props.data.webx?.number, "asset": props.data.webx?.item, "count": "1", 
        "memo": "", "chain": "416002"
      }
      const claimx = await TokensClaimArnd({ data: datx, client: '', service: ''})
      // console.log (claimx)

      setMemo('Please Wait... 5/5')
      datx = {
        "debit": {"number": "", "secret": ""},
        "credit": {"number": minterx?.data?.account?.number, "secret": mnemonix.data},
        "number": props.data.webx?.number, "asset": props.data.webx?.item, "count": "1", 
        "memo": "", "chain": "416002"
      }
      const transferx = await TokensTransferArnd({ data: datx, client: '', service: ''})
      // console.log (transferx)

      if (!transferx.stat) 
      return

      var salex = await AssetUnitDataSaleSet({ data: {unit: props?.data?.unit?.item, count: '1', mode: 'credit' }, srvc: '******' })
      // console.log(salex)

      var statusSet = await TransfersAssetStatusSet({data:{item: id, status:6}, client: '', service:''})
      //console.log(statusSet)

      setMemo('Success: Asset Minted')
      if(statusSet.stat){
        setTimeout(()=>{
          localStorage.clear("code")
          window.location.reload(true)
        },1000)
      }

      if (transferx.stat) {
        setMintDone(true)
        //props.done(true) 
      }

    }

  } 



  return (
    <>
      
      <WebbDividerSmall/>
      <div className='mb-2'></div>

      {/* info */}
      <div className=''>
      <p className='text-normal text-bold m-0 mx-3'>Mint Assets</p>
        <p className='mx-3 text-small'>Login with Your BharatID Account Email to continue</p>
        <p className='mx-3 text-small d-none'>Login with Your BharatID or Email to continue</p>
        <WebbDividerSmall />
      </div>

      {/* user account */}
      <div className={'mx-3'}>
        <p className='text-bold m-0 mb-2'>User Account</p>
        
        <p className='m-0'>Name: {data?.name || '******'}</p>
        <p className='m-0'>Email: {data?.mail || '******'}</p>
  
      </div>

      {/* action */}
      <div className='mx-3'>

        {/* user */}
        <div className='d-none'>

          <div className='mb-3'>
            <label className="form-label text-small">Name</label>
            <input 
              type="test" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={data?.name}
              onChange={({ target }) => {handleChange("name", target.value); }}
              disabled={loading || data?.name}
              placeholder="BharatID User" />
          </div>
          
          <div className='mb-3'>
            <label className="form-label text-small">Email</label>
            <input 
              type="test" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={data?.mail}
              onChange={({ target }) => {handleChange("mail", target.value); }}
              disabled={loading || data?.mail}
              placeholder="user@bharat.id" />
          </div>

          <div className={accountStatus ? 'mb-3': 'd-none'}>
            <label className="form-label text-small">Account</label>
            <input 
              type="test" 
              className="form-control height-md"
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={account}
              onChange={({ target }) => {handleChange("account", target.value); }}
              disabled={loading || account}
              placeholder="ABCX123..." />
          </div> 
          <div className={accountStatus ? 'd-none': 'mb-3'}>
            <label className="form-label text-small">Account</label>
            <p>Digital Credentials Account Not Linked.</p>
          </div>

          
        </div>

        <WebbDividerMedium />
        {/* PassCode */}
        <div className='mb-3'>
          <div className={accountStatus ? 'mb-3': 'd-none'}>
            <label className="form-label text-small">Enter Your Account Passcode</label>
            <input 
              type="password" 
              className={code == '' ?`form-control height-md` :'d-none'}
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={secret}
              onChange={({ target }) => {setSecret(target.value); }}
              disabled={loading || submit || mintstart }
              placeholder="123456..." />

              <input 
              type="password" 
              className={code != '' ?`form-control height-md` :'d-none'}
              style={{fontSize: '0.9rem', height: '2.7rem'}}
              value={secret}
              onChange={({ target }) => {setSecret(target.value); }}
              disabled={loading || submit || mintstart || code != '' }
              placeholder="123456..." />  
          </div>           
        </div>

        {/* memo */}
        <WebbDividerSmall />
        <p className='text-small m-0'>{memo}</p>
        <WebbDividerSmall />

        {/* action */}
        <WebbDividerSmall />
        <div className={accountStatus && !mintstart ? 'mb-3': 'd-none'}>
          <div className='d-flex justify-content-start'>
            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='submit'
                className='btn btn-primary btn-sm rounded-pill hidark border-none text-small'
                onClick={() => handleUnitTransfer()}
                disabled= {mintstart || secret.length!= 6}
              >
                Mint Now
              </button>
            </div>

            <div className='mx-2'></div>

            <div className={`${submit ? 'd-none' : ''}`}>
              <button
                type='button'
                className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
                onClick={() => handleDecline()}
                disabled= {mintstart}
              >
                Decline
              </button>
            </div>            
          </div>

          <WebbDividerMedium />
        </div>

      </div>


      {/* action */}

      {/* loader */}
      <div className={`mx-3 ${loading && submit ? '' : 'd-none'}`}>
        <p>{memo}</p>
      </div>

      {/* done */}
      <div className={`mx-3 ${!loading && submit ? '' : 'd-none'}`}>
        <p className={done ? '' : 'd-none'}>
          <i className='bx bxs-check-circle text-color-success'></i>
          <span className='ms-1'>Success. You can close this window.</span>
        </p>
        <p className={done ? 'd-none' : ''}>
          <i className='bx bxs-error-circle text-color-danger'></i>
          <span className='ms-1'>Failed</span>          
        </p>
        <p className={done ? 'd-none' : ''} onClick={() => window.location.reload}>
          <span className='p-2 back-color-tint'>Please Try Again</span>
        </p>
      </div>      

    </>
  )
}
