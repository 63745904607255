// init
import axios from 'axios'
const base =
  'https://ap-south-1.aws.data.mongodb-api.com/app/bharat-media-inmhr/endpoint'

export const MetadataCreate = async item => {
  const basx = base + '/metadata/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error?.response?.data }
  }
}
export const MediaCreate = async item => {
  const basx = base + '/media/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT || item.client
  }
  const datx = {
    data: item.data,
    srvc: process.env.REACT_APP_WEBB_SITE_SRVC || item.service
  }
  //   const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // const data = await decrypt(result.data.data,item.secret)
    return {
      code: result.status,
      stat: result.data.stat,
      data: result.data.data
    }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}
