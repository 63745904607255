export const TAXO_CATEGORIES = [
  {
    domain: 'Identity',
    mainCode: 1
  },
  {
    domain: 'Finance',
    mainCode: 2
  },
  {
    domain: 'Social',
    mainCode: 3
  },
  {
    domain: 'Academics',
    mainCode: 4
  },
  {
    domain: 'Assets',
    mainCode: 6
  },
  {
    domain: 'Work',
    mainCode: 7
  },
  {
    domain: 'Health',
    mainCode: 8
  },
  {
    domain: 'Entities',
    mainCode: 9
  }
]
