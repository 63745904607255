import axios from 'axios'
export const getModelData = async (api, payload) => {
  const head = {
    'Content-Type': 'application/json'
  }

  try {
    const result = await axios.post(api, payload, { headers: head })
    return result.data
  } catch (error) {
    return ''
  }
}
