// init
import axios from 'axios'
const base =
  'https://ap-southeast-1.aws.data.mongodb-api.com/app/bharat-creds-vizae/endpoint'

export const UserAssetList = async item => {
  const basx = base + '/credentials/list/user'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_NMBR
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_NMBR }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    return { data: { code: error.response.status, ...error.response.data } }
  }
}
