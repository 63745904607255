// wallet
const algosdk = require('algosdk')


export const CreateRandWallet = async item => {
  try {
    const account = algosdk.generateAccount()
    const mnemonic = algosdk.secretKeyToMnemonic(account.sk)
    console.log(account, mnemonic)
    return {
      data: { address: account.addr, mnemonic: mnemonic, secret: '******' }
    }
  } catch (error) {
    console.log(error)
    return { data: false }
  }
}
