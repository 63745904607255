// init
import axios from 'axios'
import services from '../data.services/data-services-snbx.json'
const SERVICE_CODE = 'assets.forms'
const base = services.data.find(item => SERVICE_CODE === item.code).link

export const FormatDetails = async item => {
  const basx = `${base}/formats/details`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_SCRT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { data: { code: result.status, ...result.data } }
  } catch (error) {
    // console.log(error)
    return { data: { code: error.response.status, ...error.response.data } }
  }
}
