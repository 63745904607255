// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'
import FormNeeded from '../webx/form-needed'
import WebbIcon from '../webx/webb-icon'

import WebbTrackerMedium from '../webx/webb-tracker-md';

import baseMedia from '../../data.media/tokenasset.png'

export default function CheckoutDetailsModule (props) {
  
  const asset = { form: 'ww' }

  // // console.log (props)
  const {id} = useParams()
  const [loading, setLoading] = useState(true)

  const [item, setItem] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setItem(props?.data)
        setLoading(false)
      }
      fetchData()
    }
  }, [id, props])

  if (loading) return <></>
  
  return (
    <>
      {/* {// console.log(item)} */}
      <WebbDividerMedium />
      

      {/* credit */}
      <div className='d-flex mx-3 align-middle'>
        <div className=''>
          {/* <span className='rounded-xx d-none'>
            <img
              src={brand}
              className='rounded-xx p-1 border shadow-sm'
              width={33}
              alt='route brand'
            ></img>
          </span> */}
          <span className='rounded-xx align-middle'>
            <Jazzicon diameter={30} seed={jsNumberForAddress(item &&item.debit.item || Date.now().toString())} /> 
          </span>
        </div>
        <div className='ms-2 align-middle'>
          <p className='text-normal text-uppercase text-bold m-0' style={{lineHeight: '1rem'}}>
            {(item && item.debit.name) || 'BharatID Inc'}
          </p>
          <p className='text-small text-color-tone text-uppercase m-0'>
            XXXX-XXXX-XXXX-{item?.debit?.item.substring(item.credit.item.length-4) || 'XXXX'}
          </p>
        </div>
        <span className='ms-2 mt-1 mb-1 text-uppercase text-bold'>
          
        </span>
      </div>


      {/* details */}
      <WebbDividerMedium />
      <div className='mx-3'>
        <p className='text-small text-color-tone text-uppercase m-0 mb-1'>{'Digital Asset'}</p>
        <p className='text-normal text-bold m-0'>{item?.meta?.name || 'BharatID Digital Asset'}</p>
        <p className='text-small m-0 mb-1'>{item?.webx?.number || '9000-0000-0000-0123'}</p>
      </div>


      {/* media */}
      <WebbDividerSmall/>
       <div className='mx-3'>
        {/* <p className='text-small m-0 mb-1'>{'Digital Credential'}</p> */}
        <div className='w-75 d-none d-md-block'>
          <div className='media-standard rounded-xd'>
            <img 
              src={item?.media?.link || baseMedia} 
              className='' alt={item?.meta?.name || 'BharatID Digital Assets'}></img>
          </div>
        </div>
        <div className='w-100 d-md-none'>
          <div className='media-standard rounded-xd'>
            <img 
              src={item?.media?.link || baseMedia} 
              className='' alt={item?.meta?.name || 'BharatID Digital Assets'}
            ></img>
          </div>

        </div>
      </div>    

      <WebbDividerSmall/>
      <div className='w-75 mx-3'>
        <p className='text-wd m-0'>{(item?.meta?.memo) || 'BharatID Assets Details'}</p>
      </div>

      {/* user */}
      <WebbDividerMedium />
      <div className='mx-3 d-none'>
        <p className='text-small m-0 mb-2'>{'User'}</p>

        <div className='d-flex'>
          <div className='d-none'>
            <span className='rounded-xx align-middle'>
              <Jazzicon diameter={30} seed={jsNumberForAddress(item &&item.credit.item || Date.now().toString())} /> 
            </span>
          </div>
          <div className=''>
            <p className='text-bold m-0 mb-2' style={{lineHeight: '1rem'}}>
              {item?.credit?.mail || 'user@bharat.id'}
            </p>
            <p className='m-0'>
              {item?.credit?.name || 'User Name'}
            </p>
          </div>
        </div>


        {/* <p className='text-normal m-0'>{(item && item.debit.mail) || '******'}</p> */}
      </div>





      {/* footer */}
      <div
        className='text-small text-color-tint d-none d-md-block mx-3'
        style={{ position: 'fixed', bottom: '1rem' }}
      >
        <p className='text-small text-color-next m-0 mb-1 mt-2'>
          <span className='text-mini'><i className='bi bi-circle-fill'></i></span>
          <span className='ms-1'>Testnet Transaction for Demo</span>
        </p>
        <WebbTrackerMedium />
        Powered by <span className='text-normal text-bold'>BharatID</span>{' '}
        <span className='ms-3'></span>
        <span>Terms</span>
        <span className='mx-1'></span>
        <span>Privacy</span>
      </div>
    </>
  )
}
