// main
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";
import WebbIconMedia from "../content/webx/webb-icon-media";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import AccountsNewModule from "../content/accounts/accounts-new";

export default function AccountsNew() {
  
  const metadata = {
    name: 'New Account',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  const asset = { user: '******' }
  const { id } = useParams()
  
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState()

  const [item, setItem] = useState()
  const [user, setUser] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)

        setLoading(false)
      }
      fetchData()
    }
  }, [id])


  return(

  <>
    <Helmet>
      <title>
        {metadata.name}{' • '}
        {process.env.REACT_APP_WEBB_SITE_NAME}{' • '}
        {process.env.REACT_APP_WEBB_SITE_LINE}
      </title>
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'mini', visible: false, data: <> </> }}
      media = {{ size: 'xtra', data: <></> }}

      content = {{ size: 'small', data: 
      <>
        <div className="container sticky-top back-color-lite">
          <WebbDividerSmall />
          <WebbIconMedia />
          <WebbDividerSmall />
        </div>

        <WebbDividerMedium />
        <div className="container">
          <AccountsNewModule />
        </div>
        

        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />

      </>
      }}
    
      footer = {{ size: 'small', data: 
      <> 
        <div className="container back-color-lite pt-2 mb-2" >
          <p className="text-mini text-color-tone m-0">
            <span>(c) 2022-2023 BharatID</span>
            <span className="mx-2">•</span>
            <span>Website • Terms • Privacy</span>
          </p>
        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}