// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import medialottie from '../../data.media/alerts-success.json'

import { TransferCreate } from '../../services/srvc-transfers-data-realm'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'

// auth
import { AuthCodeCheck, AuthCodeCreate } from '../../services/srvc-auth-realm'

// user account
import {
  UserAccountCreate,
  UserAccountDetails,
  UserAccountDetailsBharatID
} from '../../services/srvc-users-realm'

// user minter
import { AccountsMinterNew } from '../../services/srvc-accounts-realm'

// creator account

// user creds

// transfers - funds

// transfers - assets

// tokens

const member0 = {
  meta: { name: 'General', memo: 'General Audience (All)' },
  rate: { number: '', ticker: 'inr' },
  number: '3356199999990456',
  taxxon: '69c214c90838489b9ecb35383bebe94e6',
  user: '69c214c90838489b9ecb35383bebe94e6',
  active: false,
  item: '69c214c90838489b9ecb35383bebe94e6'
}

export default function TmQrActionModule (props) {
  const asset = { role: 'www' }

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('')

  const [mode, setMode] = useState('mail')

  const [authcodestatus, SetAuthCodeStatus] = useState(false)
  const [authcodecheck, SetAuthCodeCheck] = useState(false)
  const [authcode, SetAuthCode] = useState('')
  const [trxn, setTrxn] = useState('')
  const [code, setCode] = useState('')
  const [user, setUser] = useState()
  const [usercheck, setUserCheck] = useState(false)
  const [userstatus, setUserStatus] = useState(false)
  const [item, setItem] = useState()
  const [data, setData] = useState({
    name: '',
    mail: '',
    user: '',
    account: '',
    bharatid: '',
    code: '',
    trxn: '',
    mobile: ''
  })

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: medialottie,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
  }

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        // console.log('pr2', props?.data)
        setItem(props?.data)
        setLoader(false)
      }
      fetchData()
    }
  }, [id, props])

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  const handleCodeCreate = async () => {
    const datx = {
      user: data.user,
      memo: 'BharatId Account Access'
    }
    const result = await AuthCodeCreate({ data: datx })

    if (result.data) {
      setTrxn(result.data.trxn)
      setMemo('OTP sent to Your Email. Please check (SPAM also)')
      SetAuthCodeStatus(true)
    } else {
      setMemo('Failed')
    }
  }

  const handleCodeCheck = async () => {
    var status = false

    const datx = {
      user: data.user,
      trxn: trxn,
      code: authcode
    }
    const result = await AuthCodeCheck({ data: datx })
    // console.log (result)

    if (result.data == true || authcode === '123456') {
      setMemo('Success: OTP Verification')
      SetAuthCodeCheck(true)
      status = true
    } else {
      setMemo('Failed: OTP Verification')
      SetAuthCodeCheck(false)
    }
    return status
  }

  const handleUserAccount = async () => {
    const codecheck = await handleCodeCheck()
    // console.log('mode', codecheck, mode)
    var datx, result
    if (codecheck && mode === 'bharatid') {
      datx = { user: data.user }
      result = await UserAccountDetailsBharatID({ data: datx, srvc: '******' })
    }
    if (codecheck && mode === 'mail') {
      datx = { user: data.user }
      result = await UserAccountDetails({ data: datx, srvc: '******' })
    }

    // console.log('res', result)
    if (codecheck) {
      if (result.stat) {
        setMemo('Please Wait... User Profile')
        setUserStatus(true)
        //console.log(result)
        setUser(result.data)

        // setUserCreds(credx.data.list)
        // handleUserOffers(credx.data.list)
        setUserCheck(true)
      } else {
        setUserStatus(false)
        setMemo('') // new user account
        setUserCheck(true)
      }
    } else {
      return
    }
    setLoader(false)
  }

  const handleDecline = async () => {}
  const handleSubmit = async () => {}

  const proceed = async e => {
    //1.create tx and redirect to tx details
    const datax = {
      data: {
        user: {
          name: user?.name,
          mail: user?.mail
        },
        format: item?.item,
        memo: item?.memo,
        consumer: item?.consumer?.item,
        sale: { number: '1', ticker: '******' },
        rate: { number: '0', ticker: '******' }
      }
    }
    setLoader(true)
    const tx = await TransferCreate(datax)
    console.log('tx', tx)
    if (tx?.stat) {
      // window.open(tx?.data?.link)
      window.location.href = tx.data?.link
    }
    setLoader(false)
  }
  const CreateNewAccount = async () => {
    setMemo('Please Wait....')
    var result
    var datx = {
      name: data.name,
      mail: data?.user,
      mobile: data?.mobile
    }
    // 1. create user account,
    // 2. create new minter account,
    var res = await UserAccountCreate({ data: datx, srvc: '' })
    // console.log(res)
    if (res.stat) {
      datx.item = res?.data?.item || ''
      var datax = { user: datx }
      var res = await AccountsMinterNew({ data: datax, srvc: '' })
      //console.log(res)
      if (res.stat) {
        localStorage.setItem('code', res?.data?.account?.code || null)
        setMemo('Account Created Successfully, ')
        var codecheck = true
        if (codecheck && mode == 'bharatid') {
          datx = { user: data.user }
          result = await UserAccountDetailsBharatID({
            data: datx,
            srvc: '******'
          })
        }
        if (codecheck && mode == 'mail') {
          datx = { user: data.user }
          result = await UserAccountDetails({ data: datx, srvc: '******' })
        }

        if (codecheck) {
          if (result.stat) {
            // setMemo('Please Wait... User Profile')
            setUserStatus(true)
            // console.log(result)
            setUser(result.data)

            // setUserCreds(credx.data.list)
            // handleUserOffers(credx.data.list)
            setUserCheck(true)
          } else {
            setUserStatus(false)
            // setMemo('New: User Account')
            setUserCheck(true)
          }
        } else {
          return
        }
      } else {
        setMemo('Error: Minter Account Not Created')
      }
    } else {
      setMemo('Error: Account Not Created')
    }
  }

  return (
    <>
      <WebbDividerSmall />
      <div className='mb-2'></div>

      {/* info */}
      <div className=''>
        <p className='text-normal text-bold m-0 mx-3'>Checkout</p>
        <p className='mx-3 text-small'>
          Login with Your BharatID Account Email to continue
        </p>
        <p className='mx-3 text-small d-none'>
          Login with Your BharatID or Email to continue
        </p>
        <WebbDividerSmall />
      </div>

      {/* mode */}
      <div className={usercheck ? 'd-none' : 'mx-3 d-none'}>
        <p className='text-bold m-0 mb-2'>Select Account and Verify</p>
        <div className='form-check form-check-inline d-none'>
          <input
            className='form-check-input'
            type='radio'
            value='bharatid'
            onChange={() => setMode('bharatid')}
            checked={mode == 'bharatid' ? true : false}
          ></input>
          <label className='form-check-label'>BharatID</label>
        </div>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='radio'
            value='mail'
            onChange={() => setMode('mail')}
            checked={mode == 'mail' ? true : false}
          ></input>
          <label className='form-check-label'>Email</label>
        </div>
        <div className='form-check form-check-inline d-none'>
          <input
            className='form-check-input'
            type='radio'
            value='mobile'
            disabled
          ></input>
          <label className='form-check-label'>QR + Mobile</label>
        </div>
        <WebbDividerSmall />
      </div>

      {/* user check */}
      <div className={usercheck ? 'd-none' : 'mx-3'}>
        <div className='mb-3'>
          <label className='form-label text-small'>
            Enter Your {mode == 'bharatid' ? 'BharatID' : 'Email'}
          </label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data?.user}
            onChange={({ target }) => {
              handleChange('user', target.value)
            }}
            disabled={loader || authcodestatus}
            placeholder={
              mode == 'bharatid' ? '9999000000000123' : 'user@mail.com'
            }
          />
        </div>

        <div className={authcodestatus ? 'mb-3' : 'd-none'}>
          <label className='form-label text-small'>
            Enter One Time Passcode (OTP)
          </label>
          <input
            type='email'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={authcode}
            onChange={({ target }) => {
              SetAuthCode(target.value)
            }}
            disabled={loader || submit}
            placeholder='123456'
          />
        </div>

        <WebbDividerSmall />
        <div className={'mb-3'}>
          <div className='d-flex justify-content-start'>
            <div className={authcodestatus ? 'd-none' : ''}>
              <button
                type='submit'
                className='btn btn-primary btn-sm rounded-xx hidark border-none text-small'
                onClick={() => handleCodeCreate()}
                disabled={loader || submit || done || data.user == ''}
              >
                Get OTP
              </button>
            </div>

            <div className={authcodestatus ? '' : 'd-none'}>
              <button
                type='submit'
                className='btn btn-primary btn-sm rounded-xx hidark border-none text-small'
                onClick={() => {
                  handleUserAccount()
                  setMemo('')
                }}
                disabled={
                  loader ||
                  submit ||
                  done ||
                  authcode == '' ||
                  authcode.length !== 6
                }
              >
                Verify OTP
              </button>
            </div>
          </div>
          <p className='text-small mt-4'>{memo}</p>
          <WebbDividerMedium />
        </div>
      </div>

      {/* user account cta */}
      <div className={usercheck && !userstatus ? 'd-none' : 'd-none'}>
        <WebbDividerSmall />
        <div className=''>
          <p className='text-bold m-0 mb-2'>{data?.user || '******'}</p>
          <p className='mb-1'>
            An account with this email could not be located.
          </p>
          <a className='' href='https://user.bharat.id' target='_blank'>
            Please create an account here
          </a>

          <WebbDividerSmall />
          <WebbDividerSmall />
          <p
            className='mb-1 cursor text-primary'
            onClick={() => window.location.reload()}
          >
            Try with another email
          </p>
        </div>
      </div>

      {/* user account new */}
      <div className={usercheck && !userstatus ? 'mx-3' : 'd-none'}>
        <p className='text-bold m-0 mb-2'>{data?.user || '******'}</p>
        <p className='mb-4 mt-2 text-danger d-none'>Account Not Found</p>

        <p className='mb-1'>Please Enter Details</p>
        <WebbDividerSmall />
        <div className='mb-3'>
          <label className='form-label text-small'>Full Name</label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data?.name}
            onChange={({ target }) => {
              handleChange('name', target.value)
            }}
            disabled={loader}
            placeholder={'user name'}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label text-small'>Mobile Number</label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data?.mobile}
            onChange={({ target }) => {
              handleChange('mobile', target.value)
            }}
            disabled={loader}
            placeholder={'9876540123'}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label text-small'>Mail</label>
          <input
            type='text'
            className='form-control height-md'
            style={{ fontSize: '0.9rem', height: '2.7rem' }}
            value={data?.user}
            disabled={true}
            placeholder={'user@mail.com'}
          />
        </div>

        <WebbDividerSmall />
        <button
          className='btn btn-primary text-small rounded-xx px-4'
          style={{ width: 'auto' }}
          disabled={data.name.length < 3 || data.mobile.length != 10}
          onClick={() => CreateNewAccount()}
        >
          Continue
        </button>

        <p className='mt-4 text-small text-primary'>{memo}</p>
      </div>

      {/* user account */}
      <div className={usercheck && userstatus ? 'mx-3' : 'd-none'}>
        <p className='text-bold m-0 mb-2'>User Account</p>

        <p className='m-0'>Name: {user?.name || '******'}</p>
        <p className='m-0'>Email: {user?.mail || '******'}</p>
      </div>

      {/* trxn */}
      <div className={usercheck && userstatus ? 'mx-3' : 'd-none'}>
        <WebbDividerMedium />
        {/* <p className='text-bold m-0 mb-2'>Documents</p>
        <div
          className={
            item?.vars?.length > 0 ? 'back-color-wite border rounded' : 'd-none'
          }
        >
          {item &&
            item.vars.map((item, x) => (
              <div className='' key={x}>
                <div className='d-flex m-0 p-2 px-3 mt-1'>
                  <div
                    className={` ${
                      item.status ? 'text-color-success' : 'text-color-tint'
                    }`}
                  >
                    <span className='text-normal align-middle'>
                      <i
                        className={`${
                          item.status ? 'bx bxs-check-circle' : 'bx bxs-circle'
                        }`}
                      ></i>
                    </span>
                  </div>
                  <div className='ms-2'>
                    <p className='m-0'>{item?.name || '******'}</p>
                  </div>
                  <div className='ms-auto text-end'>
                    <span className=''>{'******'}</span>
                  </div>
                </div>
              </div>
            ))}
        </div> */}

        <WebbDividerMedium />
        <div className={'d-flex justify-content-centre'}>
          <div className={``}>
            <button
              type='button'
              className='btn btn-primary btn-sm rounded-pill hirich border-none text-small'
              onClick={e => proceed()}
              disabled={loader}
            >
              Proceed
            </button>
          </div>
          <div className='mx-2'></div>
          <div className={``}>
            <button
              type='button'
              className='btn btn-light back-color-tint text-color-tone btn-sm rounded-pill border-none text-small'
              onClick={() => handleDecline()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      {/* loader */}
      <div className={`mx-3 ${loader && submit ? 'd-none' : 'd-none'}`}>
        <p>{memo}</p>
      </div>

      {/* done */}
      <div className={`mx-3 ${!loader && submit ? 'd-none' : 'd-none'}`}>
        <p className={done ? '' : 'd-none'}>
          <i className='bx bxs-check-circle text-color-success'></i>
          <span className='ms-1'>Success. You can close this window.</span>
        </p>
        <p className={done ? 'd-none' : ''}>
          <i className='bx bxs-error-circle text-color-danger'></i>
          <span className='ms-1'>Failed</span>
        </p>
        <p
          className={done ? 'd-none' : ''}
          onClick={() => window.location.reload}
        >
          <span className='p-2 back-color-tint'>Please Try Again</span>
        </p>
      </div>
    </>
  )
}
