import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import WebbDateTimeMedium from '../content/webx/webb-datetime-md'
import WebbDividerMedium from '../content/webx/webb-divider-md'
import ContentFormat from '../content/webz/content-format-xz'

import TransferActionModule from '../content/transfers/transfers-action'
import TransferDetailsModule from '../content/transfers/transfers-details'
import TransferLoaderModule from '../content/transfers/transfers-loader'
import TransferStatusModule from '../content/transfers/transfers-status'

import TransferActionIdsModule from '../content/transfers/transfers-ids-action'
import {
  TransferDataDetails,
  TransferDataStatus
} from '../services/srvc-transfers-data-realm'
import Error from '../views.xz/Error'
import TransferActionIdsDemoModule from '../content/transfers/transfers-ids-action-demo'

export default function TransfersDataDemo () {
  const metadata = {
    name: 'Authorize Data Transfer',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const asset = { form: 'ww' }
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState()
  const [is401, set401] = useState(false)
  const [item, setItem] = useState()
  const [user, setUser] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoading(true)
        const datx = { data: { item: id } }
        const resStatus = await TransferDataStatus(datx)
        setStatus({ code: 200 || resStatus?.data?.status }) //resStatus?.data?.status
        if ([200, 201].includes(200 || resStatus?.data?.status)) {
          const resTransfer = await TransferDataDetails(datx)
          setItem(resTransfer?.data)
        }
        setLoading(false)
      }
      fetchData()
    }
  }, [id])

  if (loading) {
    return <TransferLoaderModule />
  } else if (!loading && status && ![200, 201, 415].includes(status.code)) {
    return <TransferStatusModule data={status} />
  } else if (is401) {
    return <Error />
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {' • '}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: 'medium',
          visible: false,
          data: <></>
        }}
        media={{ size: 'xtra', data: <></> }}
        content={{
          size: 'xtra',
          data: (
            <>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-1 d-none d-md-block back-color-wite'></div>
                  <div className='col-md-5 back-color-wite'>
                    <TransferDetailsModule data={item} />
                    <WebbDividerMedium />
                  </div>

                  <div
                    className='col-md-5 back-color-lite  d-none d-md-block'
                    style={{ minHeight: '100vh' }}
                  >
                    {window.location.pathname.includes('idx') ? (
                      <TransferActionIdsDemoModule
                        data={item}
                        approver={user}
                        source='link'
                      />
                    ) : (
                      <TransferActionIdsDemoModule
                        data={item}
                        approver={user}
                        source='link'
                      />
                    )}
                  </div>

                  <div className='col-md-5 back-color-lite d-md-none'>
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />
                    <WebbDividerMedium />

                    <div
                      className='text-small text-color-tint mx-3'
                      style={{ position: 'relative', bottom: '1.5rem' }}
                    >
                      <WebbDateTimeMedium />
                      <p className='text-small text-color-next m-0 mb-1'>
                        <span className='text-mini'>
                          <i className='bi bi-circle-fill'></i>
                        </span>
                        <span className='ms-1'>
                          Testnet Transaction for Demo
                        </span>
                      </p>
                      Powered by
                      <span className='text-normal text-bold'>BharatID</span>
                      <span className='ms-3'></span>
                      <span>Terms</span>
                      <span className='mx-1'></span>
                      <span>Privacy</span>
                    </div>
                  </div>

                  <div
                    className='col-md-1 back-color-lite d-none d-md-block'
                    style={{ minHeight: '100vh' }}
                  ></div>
                </div>
              </div>
            </>
          )
        }}
        footer={{
          size: 'medium',
          data: (
            <>
              <div className='text-center back-color-lite fixed-bottom pt-2 mb-1 d-none'>
                www.bharat.id
              </div>
            </>
          )
        }}
      />
    </>
  )
}
