// init
import axios from 'axios'
import services from '../data.services/data-services-snbx.json'
const SERVICE_CODE = 'transfers.data'
const base = services.data.find(item => SERVICE_CODE === item.code).link

export const TransferDataStatus = async item => {
  const basx = `${base}/transfers/data/status`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_SCRT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransferDataDetails = async item => {
  const basx = base + '/transfers/data/details'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_SCRT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransferDataMatch = async item => {
  const basx =
    'https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-nttke/endpoint/transfers/data/match'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransferDataSubmit = async item => {
  const basx = base + '/transfers/data/submit'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransferDataDecline = async item => {
  const basx = base + '/transfers/data/decline'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransferCreate = async item => {
  const basx = base + '/transfers/data/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_SCRT
  }

  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}
